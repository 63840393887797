import React from 'react';
import { v4 } from 'uuid';
import { Modal, Input, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { SettingsSection, PatternsSection, UserItem, SettingsModal, PurpleButton } from '@components';
import { SimpleModeStore, UsersConferenceStore, UserMediaStore } from '@services';
import { DEFAULT_FRAME_RATE } from '@constants';
import { ScreenShareOffIcon } from '@assets/icons';
import './styles.scss';

interface IProps {
    SimpleModeStore?: SimpleModeStore;
    UsersConferenceStore?: UsersConferenceStore;
    UserMediaStore?: UserMediaStore;
}

@inject('SimpleModeStore', 'UsersConferenceStore', 'UserMediaStore')
@observer
export class SimpleMainSection extends React.Component<IProps> {
    canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();
    youCameraRef: React.RefObject<HTMLVideoElement> = React.createRef();

    async componentDidMount() {
        const { init, setCurrentCamera, setCurrentMicro } = this.props.SimpleModeStore!;
        const { selectedCamera, selectedMicro } = this.props.UserMediaStore!;

        await init(this.canvasRef.current, this.youCameraRef.current);
        setCurrentCamera(selectedCamera ? selectedCamera.deviceId : null);
        setCurrentMicro(selectedMicro ? selectedMicro.deviceId : null);
    }

    selectLink = () => {
        const input: HTMLInputElement = document.querySelector('.copy-link-input');

        if (input) {
            input.select();
        }
    };

    copyLink = () => {
        this.selectLink();
        document.execCommand('copy');
        message.info('Link copied');
        window.getSelection().removeAllRanges();
        Modal.destroyAll();
    };

    onConferenceStart = async (): Promise<void> => {
        try {
            const { name } = this.props.UserMediaStore!;
            // @ts-ignore
            const canvasStream: MediaStream = await this.props.SimpleModeStore!.canvas.getElement().captureStream(DEFAULT_FRAME_RATE);
            const streamHasAudio = this.props.SimpleModeStore!.cameraStream?.getAudioTracks();
            const stream = new MediaStream([
                canvasStream.getVideoTracks()[0],
                streamHasAudio ? streamHasAudio[0] : undefined,
            ]);
            const roomId = v4();

            this.props.UsersConferenceStore!.start(stream, name, roomId, true, () => {});

            const link = `${location.origin}/?roomid=${roomId}`;

            Modal.info({
                okButtonProps: {
                    className: 'purple-button',
                },
                className: 'add-user-modal',
                width: 500,
                title: (
                    <div>
                        Link to join
                        <div style={{ display: 'flex', paddingTop: '24px' }}>
                            <Input
                                className='copy-link-input'
                                value={link}
                                readOnly={true}
                                onFocus={this.selectLink}
                                style={{ marginRight: '12px' }}
                            />
                            <PurpleButton onClick={this.copyLink}>
                                <CopyOutlined />
                            </PurpleButton>
                        </div>
                    </div>
                ),
                closable: true,
            });
        } catch (error) {
            console.error(error);
        }
    };

    onSettingsSave = async (
        camera: string,
        micro: string,
        audio: string,
    ) => {
        const { updateMicro, updateCamera } = this.props.SimpleModeStore!;

        await updateCamera(camera);
        await updateMicro(micro);
    };

    render(): React.ReactNode {
        const {
            addScreenShare,
            selectPattern,
            usersOrder,
            toggleUser,
            currentPattern,
            isAudioMuted,
            toggleAudio,
            isMicroMuted,
            isVideoMuted,
            toggleMicros,
            toggleVideo,
            isSettingsModalShown,
            closeSettingsModal,
            openSettingsModal,
            currentCamera,
            currentMicro,
            addCamera,
            isScreenShare,
        } = this.props.SimpleModeStore!;
        const { streams, muteUser } = this.props.UsersConferenceStore!;
        const { name } = this.props.UserMediaStore!;

        return (
            <div className='simple-main-section'>
                <div className='simple-main-section__top'>
                    <div className='simple-main-section__wrapper'>
                        <canvas className='simple-main-section__canvas' ref={this.canvasRef} />
                    </div>
                    <div className='simple-main-section__actions'>
                        <SettingsSection
                            onSettingsClick={() => openSettingsModal()}
                            onAudioClick={() => toggleAudio()}
                            showAddUser={true}
                            onCameraClick={() => toggleVideo()}
                            onMicroClick={() => {
                                if (isMicroMuted) {
                                    toggleMicros(true);
                                } else {
                                    toggleMicros(false);
                                }
                            }}
                            onScreenShareClick={() => addScreenShare()}
                            onAddUserClick={() => this.onConferenceStart()}
                            buttonOptions={{
                                isAudioMuted,
                                isVideoMuted,
                                isMicroMuted,
                                isAudioOff: false,
                                isVideoOff: false,
                                isMicroOff: false,
                            }}
                        />
                        <PatternsSection onPatternSelect={selectPattern} selectedPattern={currentPattern} />
                    </div>
                </div>
                <div className='simple-main-section__bottom'>
                    <div className='simple-main-section__you-wrapper'>
                        <div className='simple-main-section__you-top'>
                            <div className='simple-main-section__you-name'>
                                <FormattedMessage id='you' />
                            </div>
                        </div>
                        <div className='simple-main-section__you-video-wrapper'>
                            <video
                                className='simple-main-section__you-video'
                                autoPlay={true}
                                muted={true}
                                ref={this.youCameraRef}
                            />
                            <div className='simple-main-section__you-switch-wrapper'>

                            </div>
                            {
                                isScreenShare && (
                                    <div className='simple-main-section__off-screen-share'>
                                        <div onClick={() => addCamera()} className='simple-main-section__off-screen-icon'>
                                            <ScreenShareOffIcon />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        usersOrder.map((order) => {
                            const { stream, name, id, isMuted } = streams[order.id];

                            return (
                                <UserItem
                                    soundOff={isAudioMuted}
                                    key={id}
                                    stream={stream}
                                    name={name}
                                    mute={() => muteUser(id)}
                                    isMuted={isMuted}
                                    withMute={true}
                                    signal={100}
                                    isEnabled={order.isEnabled}
                                    withEnabled={true}
                                    toggleUser={() => toggleUser(order.id)}
                                />
                            );
                        })
                    }
                </div>
                {
                    isSettingsModalShown && (
                        <SettingsModal
                            closeModal={closeSettingsModal}
                            showModal={isSettingsModalShown}
                            selectedCamera={currentCamera}
                            selectedMicro={currentMicro}
                            saveDevices={this.onSettingsSave}
                        />
                    )
                }
            </div>
        );
    }
}
