import { MessageDescriptor, IntlShape } from 'react-intl';

export class DependencyInjector {
    private static instance: DependencyInjector;
    private _intl: IntlShape;

    private constructor() {}

    static getInstance(): DependencyInjector {
        if (!DependencyInjector.instance) {
            DependencyInjector.instance = new DependencyInjector();
        }

        return DependencyInjector.instance;
    }

    set intl(intl: IntlShape) {
        this._intl = intl;
    }

    get intl(): IntlShape {
        return this._intl;
    }

    formatMessage(message: MessageDescriptor): string {
        return this._intl.formatMessage(message);
    }

    fm(id: string): string {
        return this.formatMessage({ id });
    }
}
