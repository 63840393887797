import { action, observable, makeObservable } from 'mobx';
import { v4 } from 'uuid';
import { RootStore } from '@services';
import { DEFAULT_FRAME_RATE, STORAGE_CAMERA_LABEL, STORAGE_MICRO_LABEL } from '@constants';
import { Base } from './base-store';

export class GuestStore extends Base {
    video: HTMLVideoElement;
    updateId: string = v4();
    isCameraView: boolean = true;
    stream: MediaStream;
    isVideoMuted: boolean = false;
    isMicroMuted: boolean = false;
    isAudioMuted: boolean = false;
    isAudioOff: boolean = false;
    isVideoOff: boolean = true;
    isMicroOff: boolean = true;
    screenShareStream: MediaStream;
    resultMediaStream: MediaStream;
    isSettingsModalShown: boolean = false;
    currentCamera: string = null;
    currentMicro: string = null;
    isMutedByAdmin: boolean = false;

    constructor(rootStore: RootStore) {
        super(rootStore);

        makeObservable(this, {
            isCameraView: observable,
            updateId: observable,
            isMutedByAdmin: observable,
            isMicroMuted: observable,
            isVideoMuted: observable,
            isVideoOff: observable,
            isMicroOff: observable,
            isSettingsModalShown: observable,
            isAudioMuted: observable,
            isAudioOff: observable,
            currentCamera: observable,
            currentMicro: observable,
            setIsCameraView: action,
            setUpdateId: action,
            toggleVideo: action,
            toggleSound: action,
            addCamera: action,
            toggleAudio: action,
            openSettingsModal: action,
            closeSettingsModal: action,
            updateCamera: action,
            updateMicro: action,
            stopScreenShare: action,
            muteByAdmin: action,
        });
    }

    setCurrentCamera = (camera: string) => {
        this.currentCamera = camera;
        localStorage.setItem(STORAGE_CAMERA_LABEL, camera);
    };

    setCurrentMicro = (micro: string) => {
        this.currentMicro = micro;
        localStorage.setItem(STORAGE_MICRO_LABEL, micro);
    };

    openSettingsModal = () => {
        this.isSettingsModalShown = true;
    };

    closeSettingsModal = () => {
        this.isSettingsModalShown = false;
    };

    setUpdateId = () => {
        this.updateId = v4();
    };

    setIsCameraView = (isCameraView: boolean) => {
        this.isCameraView = isCameraView;
    };

    init = async (videoElement: HTMLVideoElement) => {
        this.video = videoElement;

        if (this.rootStore.userMediaStore.selectedMicro || this.rootStore.userMediaStore.selectedCamera) {
            await this.addCamera();
        }
    };

    stopScreenShare = () => {
        if (this.screenShareStream) {
            this.screenShareStream.getTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
            this.screenShareStream = undefined;
        }
    };

    addCamera = async (cameraId?: string, microId?: string) => {
        try {
            this.setIsCameraView(true);
            this.stopScreenShare();

            const config: MediaStreamConstraints = {
                audio: false,
                video: false,
            };

            if (microId || this.rootStore.userMediaStore.selectedMicro?.deviceId) {
                this.isMicroOff = false;
                config.audio = {
                    deviceId: microId || this.rootStore.userMediaStore.selectedMicro?.deviceId,
                };
            }

            if (cameraId || this.rootStore.userMediaStore.selectedCamera?.deviceId) {
                this.isVideoOff = false;
                config.video = {
                    width: 1280,
                    height: 720,
                    deviceId: cameraId || this.rootStore.userMediaStore.selectedCamera.deviceId,
                };
            }

            const stream = await navigator.mediaDevices.getUserMedia(config);

            if (this.stream) {
                this.stream.getTracks().forEach((track) => {
                    track.stop();
                });
            }

            this.video.srcObject = stream;
            this.video.play();
            this.stream = stream;
        } catch (error) {
            console.error(error);
        }
    };

    updateCamera = async (camera: string) => {
        try {
            if (camera !== this.currentCamera) {
                this.stopScreenShare();
                this.currentCamera = camera;

                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.currentMicro,
                    },
                    video: {
                        width: 1280,
                        height: 720,
                        deviceId: this.currentCamera,
                    }
                });

                if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }

                this.video.srcObject = stream;
                this.video.play();
                this.stream = stream;

                this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateMicro = async (micro: string) => {
        try {
            if (micro !== this.currentMicro) {
                this.currentMicro = micro;

                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.currentMicro,
                    },
                    video: {
                        width: 1280,
                        height: 720,
                        deviceId: this.currentCamera,
                    }
                });

                if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }

                this.video.srcObject = stream;
                this.video.play();
                this.stream = stream;

                this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);
            }
        } catch (error) {
            console.error(error)
        }
    };

    addScreenShare = async () => {
        try {
            // @ts-ignore
            const stream: MediaStream = await navigator.mediaDevices.getDisplayMedia(
                {
                    video: {
                        cursor: 'always',
                        width: 1920,
                        height: 1080,
                    },
                    audio: true,
                }
            );

            this.setIsCameraView(false);

            this.video.srcObject = stream;
            this.video.play();
            this.screenShareStream = stream;

            const audioTracks = stream.getAudioTracks();

            if (audioTracks && audioTracks[0]) {
                const audioSteam = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.currentMicro,
                    },
                });
                const audioContext = new AudioContext();
                const destination = audioContext.createMediaStreamDestination();
                const audioScreenShare = audioContext.createMediaStreamSource(stream);
                const audioCamera = audioContext.createMediaStreamSource(audioSteam);

                audioScreenShare.connect(destination);
                audioCamera.connect(destination);

                this.rootStore.usersConferenceStore.changeTrack(destination.stream.getAudioTracks()[0]);
            } else {
                const audioSteam = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.currentMicro,
                    },
                });
                this.rootStore.usersConferenceStore.changeTrack(audioSteam.getAudioTracks()[0]);
            }

            this.rootStore.usersConferenceStore.changeTrack(stream.getVideoTracks()[0]);

            stream.getVideoTracks()[0].onended = async () => {
                try {
                    this.setIsCameraView(true);
                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: {
                            deviceId: this.currentMicro,
                        },
                        video: {
                            width: 1280,
                            height: 720,
                            deviceId: this.currentCamera,
                        }
                    });

                    if (this.stream) {
                        this.stream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    }

                    this.video.srcObject = stream;
                    this.video.play();
                    this.stream = stream;
                    this.screenShareStream = undefined;
                    this.rootStore.usersConferenceStore.changeTrack(this.stream.getVideoTracks()[0]);
                    this.rootStore.usersConferenceStore.changeTrack(this.stream.getAudioTracks()[0]);

                    this.setUpdateId();
                } catch (error) {
                    console.error(error);
                }
            };
        } catch (error) {
            console.error(error);
        }
    };

    getAudioTracks = (): MediaStreamTrack[] => {
        const audioTracks = this.stream.getAudioTracks();

        if (!this.setIsCameraView && this.screenShareStream) {
            const screenShareAudioTracks = this.screenShareStream.getAudioTracks();

            audioTracks.push(...screenShareAudioTracks);
        }

        return audioTracks ? audioTracks : [];
    };

    getResultedMediaStream = async () => {
        try {
            // @ts-ignore
            const videoStream: MediaStream = await this.video.captureStream(DEFAULT_FRAME_RATE);
            const audioTracks = this.getAudioTracks();
            const result = new MediaStream([
                videoStream.getVideoTracks()[0],
                ...audioTracks,
            ]);
            this.resultMediaStream = result;

            return result;
        } catch (error) {
            console.error(error);
        }

        return null;
    };

    toggleVideo = () => {
        const cameraTracks = this.stream?.getVideoTracks();
        const screenShareTracks = this.screenShareStream?.getVideoTracks();

        if (cameraTracks && cameraTracks.length) {
            this.isVideoMuted = cameraTracks[0].enabled;
            cameraTracks[0].enabled = !(cameraTracks[0].enabled);
        }

        if (screenShareTracks && screenShareTracks.length) {
            this.isVideoMuted = screenShareTracks[0].enabled;
            screenShareTracks[0].enabled = !(screenShareTracks[0].enabled);
        }
    };

    toggleSound = () => {
        if (!this.isMutedByAdmin) {
            this.toggleMicros();
        }
    };

    toggleMicros = () => {
        const cameraTracks = this.stream?.getAudioTracks();
        const screenShareTracks = this.screenShareStream?.getAudioTracks();

        if (cameraTracks && cameraTracks.length) {
            this.isMicroMuted = cameraTracks[0].enabled;
            cameraTracks[0].enabled = !(cameraTracks[0].enabled);
        }

        if (screenShareTracks && screenShareTracks.length) {
            this.isMicroMuted = screenShareTracks[0].enabled;
            screenShareTracks[0].enabled = !(screenShareTracks[0].enabled);
        }
    };

    toggleAudio = () => {
        this.isAudioMuted = !this.isAudioMuted;
    };

    muteByAdmin = () => { // todo dry
        this.isMutedByAdmin = !this.isMutedByAdmin;

        const cameraTracks = this.stream?.getAudioTracks();
        const screenShareTracks = this.screenShareStream?.getAudioTracks();

        if (cameraTracks && cameraTracks.length) {
            this.isMicroMuted = this.isMutedByAdmin;
            cameraTracks[0].enabled = !this.isMutedByAdmin;
        }

        if (screenShareTracks && screenShareTracks.length) {
            this.isMicroMuted = this.isMutedByAdmin;
            screenShareTracks[0].enabled = !this.isMutedByAdmin;
        }
    };
}
