import React from 'react';

interface IProps {}

export const MicroIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00001 10.6667C7.38334 10.6667 8.50001 9.55001 8.50001 8.16667V3.16667C8.50001 1.78334 7.38334 0.666672 6.00001 0.666672C4.61668 0.666672 3.50001 1.78334 3.50001 3.16667V8.16667C3.50001 9.55001 4.61668 10.6667 6.00001 10.6667ZM10.925 8.16667C10.5167 8.16667 10.175 8.46667 10.1083 8.87501C9.76668 10.8333 8.05834 12.3333 6.00001 12.3333C3.94168 12.3333 2.23334 10.8333 1.89168 8.87501C1.82501 8.46667 1.48334 8.16667 1.07501 8.16667C0.566676 8.16667 0.166676 8.61667 0.241676 9.11667C0.650009 11.6167 2.65001 13.575 5.16668 13.9333V15.6667C5.16668 16.125 5.54168 16.5 6.00001 16.5C6.45834 16.5 6.83334 16.125 6.83334 15.6667V13.9333C9.35001 13.575 11.35 11.6167 11.7583 9.11667C11.8417 8.61667 11.4333 8.16667 10.925 8.16667Z" fill="white"/>
        </svg>
    );
};
