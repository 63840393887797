import React from 'react';

interface IProps {}

export const AddUserIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.825 9.13333C11.425 8.41666 9.60834 7.83333 7.50001 7.83333C5.39168 7.83333 3.57501 8.41666 2.17501 9.13333C1.34168 9.55833 0.833344 10.4167 0.833344 11.35V13.6667H14.1667V11.35C14.1667 10.4167 13.6583 9.55833 12.825 9.13333ZM7.50001 6.99999C9.34168 6.99999 10.8333 5.50833 10.8333 3.66666C10.8333 1.82499 9.34168 0.333328 7.50001 0.333328C5.65834 0.333328 4.16668 1.82499 4.16668 3.66666C4.16668 5.50833 5.65834 6.99999 7.50001 6.99999ZM16.6667 4.49999V2.83333C16.6667 2.37499 16.2917 1.99999 15.8333 1.99999C15.375 1.99999 15 2.37499 15 2.83333V4.49999H13.3333C12.875 4.49999 12.5 4.87499 12.5 5.33333C12.5 5.79166 12.875 6.16666 13.3333 6.16666H15V7.83333C15 8.29166 15.375 8.66666 15.8333 8.66666C16.2917 8.66666 16.6667 8.29166 16.6667 7.83333V6.16666H18.3333C18.7917 6.16666 19.1667 5.79166 19.1667 5.33333C19.1667 4.87499 18.7917 4.49999 18.3333 4.49999H16.6667Z" fill="white"/>
        </svg>

    );
};
