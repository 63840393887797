import React from 'react';
import './styles.scss';

interface IProps {

}


export class AdvancedMode extends React.Component<IProps> {
    render(): React.ReactNode {
        return (
            <div className='advanced-mode'>

            </div>
        );
    }
}
