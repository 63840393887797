import React from 'react';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    LogoIcon,
    YouTubeIcon,
    OnlyFansIcon,
    TwitchIcon,
    FacebookIcon,
    RTMPIcon,
} from '@assets/icons';
import { Routes } from '@common-types';
import bg from '../../../assets/images/bg.png';
import './styles.scss';

interface IProps {

}
// todo rename
export class Home extends React.Component<IProps> {
    render(): React.ReactNode {
        const channelsList = [
            {
                id: v4(),
                icon: <YouTubeIcon />,
                text: <FormattedMessage id='youtube' />
            },
            {
                id: v4(),
                icon: <OnlyFansIcon />,
                text: <FormattedMessage id='onlyfans' />
            },
            {
                id: v4(),
                icon: <TwitchIcon />,
                text: <FormattedMessage id='twitch' />
            },
            {
                id: v4(),
                icon: <FacebookIcon />,
                text: <FormattedMessage id='facebook' />
            },
            {
                id: v4(),
                icon: <RTMPIcon />,
                text: <FormattedMessage id='custom' />
            },
        ];

        return (
            <div className='home'>
                <div className='home__bg' style={{ backgroundImage: `url(${bg})` }} />
                <div className='home__wrapper'>
                    <div className='home__logo'>
                        <LogoIcon />
                    </div>
                    <div className='home__header'>
                        <div className='home__text'>
                            <FormattedMessage id='welcome' />
                        </div>
                        <div className='home__description'>
                            <FormattedMessage id='add.channels' />
                        </div>
                    </div>
                    <div className='home__items'>
                        {
                            channelsList.map(({ id, icon, text }) => {
                                return (
                                    <Link to={`${Routes.SimpleMode}${location.search}`} key={id}>
                                        <div className='home__item'>
                                            <div className='home__icon'>
                                                {icon}
                                            </div>
                                            <div className='home__item-name'>
                                                {text}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
