import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import { PurpleButton } from '@components';
import { BroadcastIcon, LogoIcon } from '@assets/icons';
import { NavBarTabs, Routes } from '@common-types';
import { SimpleModeStore } from '@services';
import { Branding } from '../branding';
import { SimpleMainSection } from '../main-section';
import './styles.scss';

interface IProps {
    SimpleModeStore?: SimpleModeStore;
}

@inject('SimpleModeStore')
@observer
export class SimpleMode extends React.Component<IProps> {
    onTabChange = (tab: string) => {
        this.props.SimpleModeStore!.changeTab(tab);
    };

    startStream = () => {
        this.props.SimpleModeStore!.startStream();
    };

    render(): React.ReactNode {
        const {
            streamDate,
            streamName,
            tab,
        } = this.props.SimpleModeStore!;

        return (
            <div className='simple-mode'>
                <div className='simple-mode__left'>
                    <Link to={Routes.SelectMedia}>
                        <PurpleButton>
                            <LogoIcon />
                        </PurpleButton>
                    </Link>
                </div>
                <div className='simple-mode__middle'>
                    <div className='simple-mode__header'>
                        <div className='simple-mode__text'>
                            <div className='simple-mode__name'>
                                {streamName}
                            </div>
                            <div className='simple-mode__date'>
                                {streamDate}
                            </div>
                        </div>
                        <div className='simple-mode__button'>
                            <PurpleButton onClick={this.startStream}>
                                <BroadcastIcon /> <FormattedMessage id='go.live' />
                            </PurpleButton>
                        </div>
                    </div>
                    <SimpleMainSection />
                </div>
                <div className='simple-mode__right'>
                    <div className='simple-mode__right-border' />
                    <Tabs defaultActiveKey={tab} onChange={this.onTabChange}>
                        <Tabs.TabPane
                            tab={<FormattedMessage id={NavBarTabs.Branding} />}
                            key={NavBarTabs.Branding}
                        >
                            <Branding />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key={NavBarTabs.Captions}
                            tab={<FormattedMessage id={NavBarTabs.Captions} />}
                        >
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            key={NavBarTabs.Chat}
                            tab={<FormattedMessage id={NavBarTabs.Chat} />}
                        >
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}
