import React from 'react';
import { Collapse, Switch, Select } from 'antd';
import { BlockPicker } from 'react-color';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { SimpleModeStore } from '@services';
import { ImagesList } from '@components';
import { IImage, LogoPositionsEnum } from '@common-types';
import './styles.scss';

interface IProps {
    SimpleModeStore?: SimpleModeStore;
}

enum BrandingPanels {
    BrandingSettings = 'BrandingSettings',
    Logotype = 'Logotype',
    Screensavers = 'Screensavers',
    Backgrounds = 'ScreeBackgroundsnsavers',
}

const { Option } = Select;

// todo i18n
@inject('SimpleModeStore')
@observer
export class Branding extends React.Component<IProps> {
    showColorPicker: boolean = false;

    constructor(props: IProps) {
        super(props);

        makeObservable(this, {
            showColorPicker: observable,
            toggleColorPicker: action,
        });
    }

    toggleColorPicker = () => {
        this.showColorPicker = !this.showColorPicker;
    };

    selectLogoPosition = (value: string) => {
        this.props.SimpleModeStore!.setLogoPosition(value as LogoPositionsEnum);
    };

    render(): React.ReactNode {
        const {
            addLogo,
            addBg,
            showParticipantNames,
            primaryColor,
            setPrimaryColor,
            toggleParticipantNames,
            logoPosition,
            selectedBg,
            selectedLogo,
        } = this.props.SimpleModeStore!;

        return (
            <div className='branding'>
                <Collapse
                    defaultActiveKey={[
                        BrandingPanels.BrandingSettings,
                        BrandingPanels.Logotype,
                        BrandingPanels.Screensavers,
                        BrandingPanels.Backgrounds,
                    ]}
                >
                    <Collapse.Panel header={<FormattedMessage id='branding.settings' />} key={BrandingPanels.BrandingSettings}>
                        <div className='branding__primary-color' style={{ marginTop: '20px' }}>
                            <div style={{ marginBottom: ' ' }}>
                                Primary Color
                            </div>
                            <div style={{
                                display: 'flex',
                                padding: '8px',
                                background: '#222B33',
                                borderRadius: '4px',
                            }} onClick={this.toggleColorPicker}>
                                <div className='branding__color-preview' style={{
                                    background: primaryColor,
                                    borderRadius: '4px',
                                    width: '24px',
                                    height: '24px',
                                }} />
                                <div style={{ marginLeft: '10px' }}>
                                    {primaryColor}
                                </div>
                            </div>
                            {
                                this.showColorPicker && (
                                    <BlockPicker
                                        color={primaryColor}
                                        onChangeComplete={(color) => {
                                            this.toggleColorPicker();
                                            setPrimaryColor(color.hex)
                                        }}
                                    />
                                )
                            }

                        </div>
                        <div className='branding__switch' style={{ marginTop: '12px', marginBottom: '25px' }}>
                            <Switch
                                onChange={() => toggleParticipantNames()}
                                checked={showParticipantNames}
                            />
                            <div className='branding__label'>
                                Participiant Names
                            </div>
                        </div>
                    </Collapse.Panel>
                    <Collapse.Panel header={<FormattedMessage id='logotype' />} key={BrandingPanels.Logotype}>
                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                            <ImagesList
                                selectedSrc={selectedLogo}
                                images={[
                                    {
                                        id: '2',
                                        userId: '1',
                                        src: '/images/logo.svg'
                                    },
                                ]}
                                addNewImage={() => console.log(1)}
                                selectImage={(img: IImage) => addLogo(img)}
                            />
                            <Select
                                style={{ marginTop: '12px' }}
                                value={logoPosition}
                                onChange={this.selectLogoPosition}
                            >
                                <Option value={LogoPositionsEnum.TopRight} key={LogoPositionsEnum.TopRight}>
                                    Top Right
                                </Option>
                                <Option value={LogoPositionsEnum.TopLeft} key={LogoPositionsEnum.TopLeft}>
                                    Top Left
                                </Option>
                                <Option value={LogoPositionsEnum.BottomRight} key={LogoPositionsEnum.BottomRight}>
                                    Bottom Right
                                </Option>
                                <Option value={LogoPositionsEnum.BottomLeft} key={LogoPositionsEnum.BottomLeft}>
                                    Bottom Left
                                </Option>
                            </Select>
                        </div>
                    </Collapse.Panel>
                    {/*<Collapse.Panel header={<FormattedMessage id='screensavers' />} key={BrandingPanels.Screensavers}>

                    </Collapse.Panel>*/}
                    <Collapse.Panel header={<FormattedMessage id='backgrounds' />} key={BrandingPanels.Backgrounds}>
                        <div style={{ marginTop: '20px', }}>
                            <ImagesList
                                selectedSrc={selectedBg}
                                images={[
                                    {
                                        id: '1',
                                        userId: '1',
                                        src: '/images/hand-painted-watercolor-background-with-sky-and-clouds-shape_24972-1095.jpg'
                                    },
                                    {
                                        id: '2',
                                        userId: '1',
                                        src: '/images/w-qjCHPZbeXCQ-unsplash.jpg'
                                    },
                                    {
                                        id: '3',
                                        userId: '1',
                                        src: '/images/html-color-codes-color-tutorials-hero.jpg'
                                    },
                                    {
                                        id: '4',
                                        userId: '1',
                                        src: '/images/istockphoto-1138395421-612x612.jpg'
                                    },

                                ]}
                                addNewImage={() => console.log(1)}
                                selectImage={(img: IImage) => addBg(img)}
                            />
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    }
}
