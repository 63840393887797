import React from 'react';

interface IProps {}

export const ScreenShareOffIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6667 2.5H4.85834L6.525 4.16667H16.6667V14.3083L17.7667 15.4083C18.1083 15.1 18.325 14.6583 18.325 14.1667L18.3333 4.16667C18.3333 3.25 17.5833 2.5 16.6667 2.5Z" fill="white"/>
            <path d="M1.15836 2.34165C0.833362 2.66665 0.833362 3.19165 1.15836 3.51665L1.67503 4.03332C1.67503 4.08332 1.6667 4.12498 1.6667 4.16665V14.1666C1.6667 15.0833 2.4167 15.8333 3.33336 15.8333H6.6667V16.6666C6.6667 17.125 7.0417 17.5 7.50003 17.5H12.5C12.9584 17.5 13.3334 17.125 13.3334 16.6666V15.8333H13.475L16.4834 18.8417C16.8084 19.1667 17.3334 19.1667 17.6584 18.8417C17.9834 18.5167 17.9834 17.9917 17.6584 17.6667L2.3417 2.34165C2.0167 2.01665 1.48336 2.01665 1.15836 2.34165ZM3.33336 5.69165L11.8084 14.1666H3.33336V5.69165Z" fill="white"/>
        </svg>
    );
};
