import React from 'react';

interface IProps {}

export const SmallWebCamIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1667 3.75V0.833333C12.1667 0.375 11.7917 0 11.3333 0H1.33333C0.875 0 0.5 0.375 0.5 0.833333V9.16667C0.5 9.625 0.875 10 1.33333 10H11.3333C11.7917 10 12.1667 9.625 12.1667 9.16667V6.25L14.075 8.15833C14.6 8.68333 15.5 8.30833 15.5 7.56667V2.425C15.5 1.68333 14.6 1.30833 14.075 1.83333L12.1667 3.75Z" fill="white"/>
        </svg>
    );
};
