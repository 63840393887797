import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { APP_SELECTOR } from '@constants';
import { App } from '@components';
import { RootStore } from '@services';
import 'antd/dist/antd.css';
import './styles.scss';

const appNode: HTMLDivElement = document.querySelector(APP_SELECTOR) as HTMLDivElement;
const rootStore = new RootStore();
const stores = {
    AppStore: rootStore.appStore,
    StreamStore: rootStore.streamStore,
    GuestStore: rootStore.guestStore,
    UserMediaStore: rootStore.userMediaStore,
    SimpleModeStore: rootStore.simpleModeStore,
    UsersConferenceStore: rootStore.usersConferenceStore,
};

ReactDOM.render(
    <Provider {...stores}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    appNode,
);
