import React from 'react';

interface IProps {}

export const MicroOffIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 8.83335V4.16669C12.5 2.78335 11.3833 1.66669 9.99998 1.66669C8.71665 1.66669 7.67498 2.63335 7.53332 3.87502L12.5 8.83335ZM15.0666 9.16669C14.725 9.16669 14.425 9.41669 14.375 9.75835C14.3333 10.025 14.275 10.2917 14.1917 10.5334L15.25 11.5917C15.5 11.0917 15.6833 10.55 15.775 9.97502C15.8333 9.55002 15.5 9.16669 15.0666 9.16669ZM3.09165 2.96669C2.76665 3.29169 2.76665 3.81669 3.09165 4.14169L7.49998 8.55835V8.91669C7.49998 9.90835 7.99998 10.85 8.85832 11.3417C9.48332 11.7 10.0333 11.7084 10.5416 11.6L11.925 12.9834C11.3333 13.2584 10.675 13.4167 9.99998 13.4167C7.88332 13.4167 5.93332 11.9417 5.62498 9.75835C5.57498 9.41669 5.27498 9.16669 4.93332 9.16669C4.49998 9.16669 4.16665 9.55002 4.22498 9.97502C4.60832 12.4417 6.69165 14.3917 9.16665 14.7667V16.6667C9.16665 17.125 9.54165 17.5 9.99998 17.5C10.4583 17.5 10.8333 17.125 10.8333 16.6667V14.7667C11.5917 14.6584 12.3083 14.3917 12.9583 14.0167L15.8667 16.925C16.1917 17.25 16.7167 17.25 17.0417 16.925C17.3667 16.6 17.3667 16.075 17.0417 15.75L4.26665 2.96669C3.94165 2.64169 3.41665 2.64169 3.09165 2.96669Z" fill="white"/>
        </svg>
    );
};
