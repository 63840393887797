import {
    NAV_BAR_WIDTH,
    DEFAULT_RATIO,
    DEFAULT_CANVAS_PADDING,
    TOP_MENU_HEIGHT,
    SCENES_HEIGHT,
    CANVAS_WRAPPER_SELECTOR,
} from '@constants';
import {
    IPosition,
    ISize,
    LayerTypes,
    ICameraSettings,
    IImageSettings,
    ISlideShowSettings,
    IScreenCaptureSettings,
    ITextSettings,
    IImage,
} from '@common-types';
import { fabric } from 'fabric';

export const getCanvasSize = (): ISize => {

    const wrapper: HTMLDivElement = document.querySelector('.simple-main-section__wrapper');

    return {
        width: wrapper?.offsetHeight * 1.77777777,
        height: wrapper?.offsetHeight,
    };


    /*
    const { innerWidth, innerHeight } = window;
    const freeWidth: number =
        innerWidth - (NAV_BAR_WIDTH + (DEFAULT_CANVAS_PADDING * 2));
    const freeHeight: number =
        innerHeight - (TOP_MENU_HEIGHT + SCENES_HEIGHT + (DEFAULT_CANVAS_PADDING * 2));

    if (freeWidth / DEFAULT_RATIO <= freeHeight) {
        return { width: freeWidth, height: freeWidth / DEFAULT_RATIO };
    } else {
        return { width: freeHeight * DEFAULT_RATIO, height: freeHeight };
    }*/
};

export const getLayerDefaultSize = (layerType: LayerTypes): ISize => {
    const { width } = getCanvasSize();

    if (
        layerType === LayerTypes.Text ||
        layerType === LayerTypes.Image ||
        layerType === LayerTypes.Camera ||
        layerType === LayerTypes.SlideShow
    ) {
        return { width: width * 0.4 };
    }

    return { width };
};

export const getLayerDefaultPosition = (layerType: LayerTypes): IPosition => {
    return { left: 0, top: 0 };
};

export const getLayerDefaultSettings = (layerType: LayerTypes):
    IImageSettings |
    ICameraSettings |
    ITextSettings |
    ISlideShowSettings |
    IScreenCaptureSettings |
    {} => {
        return {};
    };

export const getCanvasPadding = (): ISize => {
    const wrapper: HTMLDivElement = document.querySelector(CANVAS_WRAPPER_SELECTOR);

    if (wrapper) {
        const { height, width } = getCanvasSize();
        const { offsetHeight, offsetWidth } = wrapper;

        return {
            width: (offsetWidth - width) / 2,
            height: (offsetHeight - height) / 2,
        };
    }

    return { height: 0, width: 0 };
};

export const createImageNode = (img: IImage) => {
    return new Promise((resolve) => {
        fabric.Image.fromURL(img.src, (node) => {
            resolve(node);
        });
    });
};
