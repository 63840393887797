import { IImage } from '@common-types';

export enum Animations {

}

export interface ISlideShowOptions {
    name: string;
    imageDuration: number;
    animationSpeed: number;
    loopDelay: number;
    random: boolean;
    images: IImage[];
    animationIntro: Animations | string;
    animationOutro: Animations | string;
}
