import React from 'react';

interface IProps {}

export const VideoIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="72" height="48" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M56 18V4C56 1.8 54.2 0 52 0H4C1.8 0 0 1.8 0 4V44C0 46.2 1.8 48 4 48H52C54.2 48 56 46.2 56 44V30L65.16 39.16C67.68 41.68 72 39.88 72 36.32V11.64C72 8.08 67.68 6.28 65.16 8.8L56 18Z" fill="white"/>
        </svg>
    );
};
