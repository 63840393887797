export class Logger {
    static info = (value: any, message: string = '') => {
        console.log(`%c ${message} ${value}`, 'color: green;');
    };

    static error = (value: any, message: string = '') => {
        console.error(`%c ${message} ${value}`, 'color: red;');
    };

    static warn = (value: any, message: string = '') => {
        console.warn(`%c ${message} ${value}`, 'color: orange;');
    };

    static log = (value: any, message: string = '') => {
        console.log(message, value);
    };
}
