import { action, observable, makeObservable } from 'mobx';
import { v4 } from 'uuid';
import { message } from 'antd';
import { io } from 'socket.io-client';
import { IUserConnection } from '@common-types';
import { Base } from './base-store';
import {
    DEFAULT_AUDIO_BITS_PER_SECOND,
    DEFAULT_CODEC,
    DEFAULT_MIME_TYPE, DEFAULT_TIME_SLICE,
    DEFAULT_VIDEO_BITS_PER_SECOND
} from '@constants';

const peerConnectionConfig = {
    iceServers: [
        {
            urls: 'stun:stun.l.google.com:19302',
        },
        {
            urls: 'stun:124.64.206.224:8800',
        },
        {
            urls: 'stun:relay.backups.cz',
        },
        {
            urls: 'stun:numb.viagenie.ca',
        },
    ]
};
const sessionDescription = window.RTCSessionDescription;
const socket = io(':8443', { secure: true });
// const socket = io();

const MAX_RETRIES = 2;
const RETRY_TIME = 5000;

export class UsersConferenceStore extends Base {
    perConnectionsMap: { [key: string]: RTCPeerConnection } = {};
    answersFrom = {};
    offer = {};
    streams: { [key: string]: IUserConnection } = {};
    localStream: MediaStream;
    toggleUserSound: () => void;
    shownPopups: string[] = [];
    retriesCount = 0;
    timerId;

    constructor(rootStore) {
        super(rootStore);

        makeObservable(this, {
            streams: observable,
            addStream: action,
            removeStream: action,
            muteUser: action,
        });
    }

    addStream = (userConnection: IUserConnection) => {
        this.rootStore.simpleModeStore.addUser(userConnection.id);
        this.streams[userConnection.id] = userConnection;

        if (!this.shownPopups.includes(userConnection.id)) {
            this.shownPopups.push(userConnection.id);
            message.info(`User ${userConnection.name} joined the conference`);
        }
    };

    removeStream = (id: string) => {
        if (this.streams[id]) {
            const name = this.streams[id].name;
            message.info(`User ${name} leaved the conference`);

            this.rootStore.simpleModeStore.removeUser(id);
            this.streams[id].stream.getTracks().forEach((track) => {
                track.stop();
            });
            this.streams[id] = undefined;
            delete this.streams[id];
        }
    };

    init = () => {

        console.log('init', socket);

        socket.on('add-users', (data) => {
            clearTimeout(this.timerId);
            console.log('add-users', data);
            for (var i = 0; i < data.users.length; i++) {
                const id = data.users[i].id;
                const name = data.users[i].name;
                const userRole = data.users[i].userRole;

                this.createOffer(id, name, userRole);
            }
        });

        socket.on('remove-user', (id) => {
            clearTimeout(this.timerId);
            console.log('remove-user', id);

            try {
                if (this.perConnectionsMap[id]) {
                    this.perConnectionsMap[id].close();
                    this.perConnectionsMap[id] = undefined;
                }

                this.removeStream(id);
            } catch (error) {
                console.error('Error during removing user', error);
            }
        });

        socket.on('answer-made', async (data) => {
            clearTimeout(this.timerId);
            console.log('answer-made', data);
            try {
                await this.perConnectionsMap[data.socket].setRemoteDescription(new sessionDescription(data.answer));

                if (!this.answersFrom[data.socket]) {
                    this.createOffer(data.socket, data.name, data.userRole);
                    this.answersFrom[data.socket] = true;
                }
            } catch (e) {
                this.error(e);
            }
        });

        socket.on('offer-made', async (data) => {
            clearTimeout(this.timerId);
            console.log('offer-made', data);
            try {
                this.offer[data.socket] = data.offer;

                if (!this.perConnectionsMap[data.socket]) {
                    this.perConnectionsMap[data.socket] = new RTCPeerConnection(peerConnectionConfig);

                    this.localStream.getTracks().forEach((track) => {
                        this.perConnectionsMap[data.socket].addTrack(track, this.localStream);
                    });

                    this.perConnectionsMap[data.socket].ontrack = (event) => {
                        console.log('ontrack event', event);
                        this.addStream({
                            stream: event.streams[0],
                            name: data.name,
                            id: data.socket,
                            userRole: data.userRole,
                            isMuted: false,
                        });
                    };
                }

                await this.perConnectionsMap[data.socket].setRemoteDescription(new sessionDescription(data.offer));
                const answer = await this.perConnectionsMap[data.socket].createAnswer();
                await this.perConnectionsMap[data.socket].setLocalDescription(new sessionDescription(answer));

                socket.emit('make-answer', {
                    answer: answer,
                    to: data.socket,
                });
            } catch (e) {
                this.error(e);
            }
        });

        socket.on('mute', (data) => {
            clearTimeout(this.timerId);
            console.log('mute');
            try {
                if (this.toggleUserSound) {
                    this.toggleUserSound();
                }
            } catch (e) {
                this.error(e);
            }
        });
    };

    muteUser = (userId: string) => {
        socket.emit('mute-user', {
            to: userId,
        });

        const userConnection = this.streams[userId];

        if (userConnection) {
            userConnection.isMuted = !userConnection.isMuted;
        }
    };


    error = (err) => {
        console.error('Error', err);
    };

    createOffer = async (id: string, name: string, userRole: string) => {
        try {
            if (!this.perConnectionsMap[id]) {
                this.perConnectionsMap[id] = new RTCPeerConnection(peerConnectionConfig);

                this.localStream.getTracks().forEach((track) => {
                    this.perConnectionsMap[id].addTrack(track, this.localStream);
                });

                this.perConnectionsMap[id].ontrack = (event) => {
                    console.log('ontrack event', event);
                    this.addStream({
                        id,
                        name,
                        userRole,
                        stream: event.streams[0],
                        isMuted: false,
                    });
                };
            }

            const offer = await this.perConnectionsMap[id].createOffer();
            await this.perConnectionsMap[id].setLocalDescription(new sessionDescription(offer));

            socket.emit('make-offer', {
                offer: offer,
                to: id,
            });
        } catch (e) {
            this.error(e);
        }
    };

    start = (
        stream: MediaStream,
        name: string,
        roomId = v4(),
        isCreator = true,
        toggleUserSound: () => void | Promise<void>,
    ) => {
        this.localStream = stream;
        this.init();
        this.toggleUserSound = toggleUserSound;

        console.log('start', name, roomId);

        if (isCreator) {
            socket.emit('create-room', { roomId, name });
        } else {
            socket.emit('start', { roomId, name });

            if (this.retriesCount < MAX_RETRIES) {
                this.retriesCount++;

                this.timerId = setTimeout(() => {
                    this.start(stream, name, roomId, isCreator, toggleUserSound);
                }, RETRY_TIME);
            }
        }
    };

    changeTrack = (newTrack: MediaStreamTrack) => {
        try {
            Object.keys(this.perConnectionsMap).forEach((id: string) => {
                const connection: RTCPeerConnection = this.perConnectionsMap[id];

                if (connection) {
                    const sender = connection.getSenders().find((senderItem: RTCRtpSender) => {
                        return senderItem.track.kind == newTrack.kind;
                    });

                    if (sender) {
                        sender.replaceTrack(newTrack);
                    }
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    // streaming
    private mediaRecorder;
    private mediaStream: MediaStream;

    startStream = async (stream: MediaStream, streamKey: string) => {
        socket.emit('start-ffmpeg');

        setTimeout(() => {
            this.mediaStream = stream;
            // @ts-ignore
            this.mediaRecorder = new MediaRecorder(this.mediaStream, {
                mimeType: `${DEFAULT_MIME_TYPE};${DEFAULT_CODEC}`,
                audioBitsPerSecond : DEFAULT_AUDIO_BITS_PER_SECOND,
                videoBitsPerSecond : DEFAULT_VIDEO_BITS_PER_SECOND,
            });

            this.mediaRecorder.addEventListener('dataavailable', (e) => {
                console.log('mediaRecorder dataavailable');
                socket.emit('data', {
                    message: e.data,
                });
            });

            this.mediaRecorder.addEventListener('stop', () => {
                // todo
            });


            this.mediaRecorder.addEventListener('start', () => {
                console.log('mediaRecorder start');
            });

            this.mediaRecorder.start(30);
        }, 3000);

    };
}
