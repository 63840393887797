import { DependencyInjector, RootStore } from '@services';

export class Base {
    protected readonly rootStore: RootStore;
    protected readonly dependencies: DependencyInjector = DependencyInjector.getInstance();

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }
}
