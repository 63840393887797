import React from 'react';
import { Switch } from 'antd';
import { AudioIcon, AudioOffIcon } from '@assets/icons';
import './styles.scss';

interface IProps {
    name: string;
    signal: number;
    stream: MediaStream;
    soundOff?: boolean;
    withMute?: boolean;
    isMuted?: boolean;
    mute?: () => void;
    soundOnly?: boolean;
    withEnabled?: boolean;
    isEnabled?: boolean;
    toggleUser?: () => void;
}

export class UserItem extends React.Component<IProps> {
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

    componentDidMount(): void {
        this.videoRef.current.srcObject = this.props.stream;
    }

    render(): React.ReactNode {
        const {
            name,
            mute,
            toggleUser,
            soundOff = false,
            withMute = false,
            soundOnly = false,
            isMuted = false,
            withEnabled = false,
            isEnabled = true,
        } = this.props;

        return (
            <div className={`user-item ${soundOnly ? 'user-hidden' : ''} ${!isEnabled ? 'user-disabled' : ''}`}>
                <div className='user-item__top-section'>
                    <div className='user-item__name'>
                        {name}
                    </div>
                    <div className='user-item__signal'>

                    </div>
                </div>
                <div className='user-item__main'>
                    <div className='user-item__bg' />
                    <div className='user-item__wrapper'>
                        <video
                            ref={this.videoRef}
                            className={`user-item__video ${soundOnly ? 'sound-only' : ''}`}
                            autoPlay={true}
                            muted={soundOff}

                        />
                    </div>
                    <div className='user-item__actions'>
                        {
                            withEnabled && (
                                <div className='user-item__switch'>
                                    <Switch
                                        checked={isEnabled}
                                        onChange={() => toggleUser()}
                                    />
                                </div>
                            )
                        }
                        {
                            withMute && (
                                <div className='user-item__mute' onClick={() => {
                                    if (mute) {
                                        mute();
                                    }
                                }}>
                                    {isMuted ? <AudioOffIcon /> : <AudioIcon /> }
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}
