import React from 'react';

interface IProps {}

export const LogoIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="777" height="777" viewBox="0 0 777 777" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M652.3 211.7H734C667.9 82.2 534.5 0 388.1 0C172 0 0 175.6 0 388.1C0 600.6 172 776.2 388.1 776.2C604.2 776.2 776.2 600.6 776.2 388.1C776.2 352.3 771.5 316.7 761.8 282.3C637.1 282.3 512.6 282.3 388.1 282.3C329.2 282.3 282.3 330.1 282.3 388.1C282.3 446.2 329.2 493.9 388.1 493.9C434.2 493.9 473.4 464.5 487.8 423.3H388.1C368.7 423.3 352.8 407.5 352.8 388C352.8 368.6 368.6 352.7 388.1 352.7H560.9C563.4 364.1 564.5 375.8 564.5 388C564.5 484.7 486.2 564.4 388.1 564.4C290 564.4 211.7 484.7 211.7 388C211.7 290.8 290.3 211.6 388.1 211.6H560.9C516.5 168 455.3 141 388.1 141C250.6 141 141.1 252.7 141.1 388C141.1 523.3 250.6 635 388.1 635C525.6 635 635.1 523.3 635.1 388C635.1 376.1 634.3 364.1 632.6 352.7H703.7C705.1 364.1 705.6 376 705.6 388C705.6 561.9 565 705.5 388.1 705.5C211.4 705.6 70.6 562 70.6 388.1C70.6 214.2 211.4 70.6 388.1 70.6C494.5 70.6 593.1 123.3 652.3 211.7Z" fill="#243747"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="776.2" height="776.2" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
