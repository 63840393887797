import React from 'react';
import {
    MicroIcon,
    SmallWebCamIcon,
    ScreenShareIcon,
    AddUserIcon,
    SettingsIcon,
    AudioIcon,
    AudioOffIcon,
    MicroOffIcon,
    CameraOffIcon,
} from '@assets/icons';
import './styles.scss';

interface IProps {
    onCameraClick?: () => void;
    onScreenShareClick?: () => void;
    onAddUserClick?: () => void;
    onAudioClick?: () => void;
    onMicroClick?: () => void;
    onSettingsClick?: () => void;
    showAddUser?: boolean;
    buttonOptions?: {
        isMicroMuted: boolean;
        isVideoMuted: boolean;
        isAudioMuted: boolean;
        isAudioOff: boolean;
        isMicroOff: boolean;
        isVideoOff: boolean;
    };
}

export class SettingsSection extends React.Component<IProps> {
    onMicroClick = () => {
        this.props.onMicroClick && this.props.onMicroClick();
    };

    onVideoClick = () => {
        this.props.onCameraClick && this.props.onCameraClick();
    };

    onAudioClick = () => {
        this.props.onAudioClick && this.props.onAudioClick();
    };

    onScreenShareClick = () => {
        this.props.onScreenShareClick && this.props.onScreenShareClick();
    };

    onAddUserIconClick = () => {
        this.props.onAddUserClick && this.props.onAddUserClick();
    };

    onSettingsClick = () => {
        this.props.onSettingsClick && this.props.onSettingsClick();
    };

    render(): React.ReactNode {
        const {
            showAddUser = false,
            buttonOptions = {
                isVideoMuted: false,
                isMicroMuted: false,
                isVideoOff: false,
                isMicroOff: false,
                isAudioMuted: false,
                isAudioOff: false,
            }
        } = this.props;
        const {
            isMicroMuted,
            isVideoMuted,
            isVideoOff,
            isMicroOff,
            isAudioMuted,
            isAudioOff,
        } = buttonOptions;

        return (
            <div className='settings-section'>
                <div
                    className={`settings-section__item ${isMicroMuted ? 'muted' : ''}`}
                    onClick={() => {
                        if (!isMicroOff) {
                            this.onMicroClick();
                        }
                    }}
                >
                    {isMicroMuted ? <MicroOffIcon /> : <MicroIcon />}
                </div>
                <div
                    className={`settings-section__item ${isVideoMuted ? 'muted' : ''}`}
                    onClick={() => {
                        if (!isVideoOff) {
                            this.onVideoClick();
                        }
                    }}
                >
                    {isVideoMuted ? <CameraOffIcon /> : <SmallWebCamIcon />}
                </div>
                <div
                    className={`settings-section__item ${isAudioMuted ? 'muted' : ''}`}
                    onClick={() => {
                        if (!isAudioOff) {
                            this.onAudioClick();
                        }
                    }}
                >
                    {isAudioMuted ? <AudioOffIcon /> : <AudioIcon />}
                </div>
                <div className='settings-section__item' onClick={this.onScreenShareClick}>
                    <ScreenShareIcon />
                </div>
                {
                    showAddUser && (
                        <div className='settings-section__item' onClick={this.onAddUserIconClick}>
                            <AddUserIcon />
                        </div>
                    )
                }
                <div className='settings-section__item' onClick={this.onSettingsClick}>
                    <SettingsIcon />
                </div>
            </div>
        );
    }
}
