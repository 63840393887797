import React from 'react';

interface IProps {}

export const ScreenShareIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 0.5H2.50001C1.58334 0.5 0.833344 1.25 0.833344 2.16667V12.1667C0.833344 13.0833 1.58334 13.8333 2.50001 13.8333H6.66668V14.6667C6.66668 15.125 7.04168 15.5 7.50001 15.5H12.5C12.9583 15.5 13.3333 15.125 13.3333 14.6667V13.8333H17.5C18.4167 13.8333 19.1583 13.0833 19.1583 12.1667L19.1667 2.16667C19.1667 1.24167 18.4167 0.5 17.5 0.5ZM16.6667 12.1667H3.33334C2.87501 12.1667 2.50001 11.7917 2.50001 11.3333V3C2.50001 2.54167 2.87501 2.16667 3.33334 2.16667H16.6667C17.125 2.16667 17.5 2.54167 17.5 3V11.3333C17.5 11.7917 17.125 12.1667 16.6667 12.1667Z" fill="white"/>
        </svg>

    );
};
