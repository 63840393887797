import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { DependencyInjector as DependencyInjectorService } from '@services';

interface IProps extends WrappedComponentProps {

}

class DependencyInjectorComponent extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        const instance: DependencyInjectorService = DependencyInjectorService.getInstance();

        instance.intl = props.intl;
    }

    render(): React.ReactNode {
        return null;
    }
}

export const DependencyInjector = injectIntl(DependencyInjectorComponent);