import React from 'react';

interface IProps {}

export const YouTubeIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="136" height="30" viewBox="0 0 136 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M28.8263 0C29.019 0.267176 29.2888 0.114504 29.4814 0.114504C32.0249 0.229008 34.5684 0.305344 37.0734 0.610687C40.5033 1.03053 42.1989 2.63359 42.7385 6.03053C43.3936 10.4198 43.4321 14.8473 43.2009 19.2748C43.1238 21.0305 42.9697 22.7863 42.6228 24.5038C42.0833 27.3282 40.3106 28.9695 37.4588 29.313C32.7186 29.8855 27.9785 29.9237 23.1998 29.9618C18.344 30 13.4497 29.8855 8.59394 29.5802C7.47634 29.5038 6.39728 29.3893 5.31822 29.1985C2.96741 28.7786 1.31029 27.2137 0.809294 24.8855C0.385378 23.0153 0.269765 21.145 0.154151 19.2748C0.154151 19.084 0.269765 18.8168 0 18.6641C0 16.2214 0 13.7405 0 11.2977C0.231227 11.145 0.115613 10.916 0.154151 10.687C0.269765 9.0458 0.385378 7.36641 0.693681 5.72519C1.23321 2.67176 2.96741 0.992366 6.08898 0.610687C8.5554 0.305344 11.0604 0.229008 13.5653 0.114504C13.7965 0.114504 14.0663 0.229008 14.2205 0C19.0762 0 23.932 0 28.8263 0ZM17.4191 8.58779C17.4191 12.9008 17.4191 17.0611 17.4191 21.374C21.1958 19.2366 24.8569 17.1374 28.6336 15C24.8569 12.8244 21.1573 10.7252 17.4191 8.58779Z" fill="#748499"/>
                <path d="M136 23.5496C135.73 23.7405 135.884 24.0458 135.846 24.2748C135.461 26.2977 134.266 27.4809 132.262 27.9008C130.99 28.1679 129.718 28.1679 128.447 27.8244C126.52 27.2901 125.595 25.8779 125.171 24.084C125.017 23.4351 124.863 22.7481 124.824 22.0992C124.747 18.9695 124.439 15.8397 125.132 12.7481C125.749 10.0382 127.291 8.8168 130.142 8.70229C130.759 8.66413 131.376 8.66413 131.992 8.77863C134.189 9.16031 135.306 10.3817 135.769 12.6336C135.846 13.0534 135.807 13.4733 136.039 13.855C136.039 15.8779 136.039 17.9008 136.039 19.9237C133.765 19.9237 131.491 19.8855 129.217 19.8855C129.025 19.8855 128.871 19.9237 128.871 20.1908C128.909 21.5649 128.832 22.9008 129.14 24.2366C129.294 24.9618 129.795 25.3053 130.528 25.3435C131.26 25.3435 131.722 25.0382 131.954 24.313C132.146 23.7786 132.223 23.2061 132.223 22.6336C132.223 22.2901 132.262 22.0992 132.686 22.1374C133.649 22.2137 134.613 22.2519 135.615 22.2901C135.846 22.2901 135.923 22.4428 136.077 22.5573C136 22.8244 136 23.2061 136 23.5496ZM128.832 15.229C128.832 15.7634 128.832 16.374 128.832 16.9847C128.832 17.2519 128.871 17.4046 129.179 17.4046C129.988 17.4046 130.797 17.4046 131.645 17.4046C131.838 17.4046 131.992 17.4046 131.992 17.1374C131.954 15.6489 132.069 14.1603 131.761 12.7099C131.607 11.9466 131.106 11.6031 130.335 11.6412C129.68 11.6412 129.333 11.9847 129.14 12.7099C128.871 13.4733 128.909 14.313 128.832 15.229Z" fill="white"/>
                <path d="M114.92 11.0305C115.691 9.84733 116.577 9.0458 117.849 8.77863C119.737 8.32061 121.356 9.12214 122.049 10.916C122.473 11.9847 122.627 13.1298 122.666 14.2748C122.82 17.2901 122.936 20.2672 122.55 23.2824C122.435 24.1603 122.242 25 121.895 25.8015C121.356 27.0611 120.508 28.0153 119.043 28.1298C117.463 28.2824 116.076 27.7863 115.074 26.4885C114.997 26.374 114.881 26.2595 114.766 26.0687C114.689 26.2214 114.65 26.3359 114.612 26.4504C114.226 27.8626 114.265 27.8626 112.839 27.8626C112.338 27.8626 111.837 27.8244 111.297 27.8626C110.835 27.9008 110.719 27.7099 110.719 27.2901C110.719 23.2824 110.719 19.2748 110.719 15.2672C110.719 10.8015 110.719 6.33588 110.719 1.87023C110.719 1.25954 110.912 1.14504 111.451 1.18321C112.338 1.22137 113.263 1.25954 114.188 1.18321C114.843 1.14504 114.92 1.37405 114.92 1.94656C114.92 4.8855 114.92 7.8626 114.92 11.0305ZM118.465 18.6641C118.465 17.5954 118.504 16.5649 118.465 15.4962C118.427 14.5038 118.388 13.5114 118.041 12.5954C117.733 11.7176 116.885 11.4122 116.076 11.9084C115.382 12.3282 114.997 12.9389 114.997 13.8168C114.997 17.0611 114.997 20.3053 114.997 23.5496C114.997 24.3511 115.421 24.8473 116.192 25C117.039 25.1908 117.618 24.8855 117.964 24.1221C118.08 23.8168 118.196 23.5115 118.234 23.1679C118.465 21.6794 118.427 20.1908 118.465 18.6641Z" fill="white"/>
                <path d="M82.5094 25.4962C81.7387 26.8702 80.8138 27.7481 79.4264 28.0916C76.96 28.7023 75.1102 27.4427 74.7633 24.9618C74.6862 24.2748 74.6477 23.626 74.6477 22.9389C74.6477 18.5496 74.6477 14.1221 74.6477 9.73283C74.6477 9.23664 74.7633 9.0458 75.3028 9.08397C76.3434 9.12214 77.3839 9.12214 78.4244 9.08397C78.8869 9.08397 79.0025 9.23664 79.0025 9.65649C79.0025 13.9313 79.0025 18.2061 79.0025 22.4809C79.0025 23.1298 79.0025 23.7786 79.1952 24.4275C79.3493 24.9237 79.6962 25.1908 80.2357 25.1908C81.2762 25.1908 82.0084 24.4656 82.0084 23.4351C82.0084 18.855 82.0084 14.2748 82.0084 9.69466C82.0084 9.19847 82.124 9.08397 82.625 9.08397C83.7041 9.12214 84.7832 9.12214 85.8622 9.08397C86.2476 9.08397 86.3247 9.19847 86.3247 9.58015C86.3247 15.5344 86.3247 21.4886 86.3247 27.4427C86.3247 27.7481 86.2861 27.9389 85.9008 27.9008C85.0529 27.8626 84.1665 27.8626 83.3187 27.9008C83.0104 27.9008 82.8948 27.7863 82.8948 27.4809C82.7407 26.9084 82.625 26.2977 82.5094 25.4962Z" fill="white"/>
                <path d="M104.63 25.6154C103.937 26.875 103.127 27.7147 101.894 28.0963C99.2349 28.936 97.1539 27.5238 96.8456 24.7757C96.73 23.5543 96.73 22.333 96.73 21.1116C96.73 17.333 96.73 13.5543 96.73 9.81389C96.73 9.27954 96.8841 9.16503 97.3851 9.16503C98.4256 9.2032 99.4662 9.2032 100.507 9.16503C100.969 9.16503 101.085 9.27954 101.085 9.73755C101.085 14.0505 101.085 18.3635 101.085 22.6765C101.085 23.249 101.046 23.8215 101.239 24.3559C101.432 25.0429 101.778 25.2719 102.511 25.2337C103.436 25.1574 104.091 24.4704 104.091 23.5543C104.091 18.9742 104.091 14.394 104.091 9.81389C104.091 9.3177 104.168 9.12686 104.746 9.16503C105.825 9.2032 106.865 9.2032 107.944 9.16503C108.33 9.16503 108.484 9.24137 108.484 9.66122C108.484 15.6154 108.484 21.5314 108.484 27.4856C108.484 27.8673 108.407 28.02 107.983 28.02C107.174 27.9818 106.364 27.9818 105.517 28.02C105.208 28.02 105.054 27.9818 105.016 27.6001C104.9 26.9895 104.784 26.4169 104.63 25.6154Z" fill="white"/>
                <path d="M72.4511 18.9313C72.4126 20.229 72.4511 21.9466 72.1043 23.6641C71.4491 26.8321 69.7534 28.2061 66.5163 28.1679C65.8226 28.1679 65.1674 28.0916 64.4738 27.9389C62.3927 27.4427 61.3907 25.9924 60.9283 24.0458C60.7356 23.2061 60.62 22.3664 60.62 21.5267C60.5429 18.7405 60.3887 15.9542 60.9283 13.2061C61.6605 9.58015 64.0498 8.43511 67.4797 8.74046C70.1774 9.00763 71.6803 10.3435 72.2199 13.9695C72.4511 15.4962 72.4896 16.9847 72.4511 18.9313ZM64.9747 18.4351C64.9747 19.6565 64.9362 20.8397 64.9747 22.0611C65.0133 22.8626 65.0518 23.6641 65.3601 24.4275C65.5528 24.9237 65.9382 25.229 66.5163 25.2672C67.0943 25.3053 67.4797 24.9618 67.7109 24.4656C67.9036 24.0458 67.9807 23.5878 68.0192 23.1298C68.2505 20.3053 68.1348 17.4809 68.0963 14.6565C68.0963 13.8931 68.0192 13.1298 67.7109 12.4046C67.4797 11.9084 67.0943 11.6031 66.5163 11.6031C65.9382 11.6031 65.5528 11.9084 65.3601 12.4046C65.206 12.8244 65.1289 13.2443 65.0518 13.6641C64.8591 15.2672 64.9747 16.8321 64.9747 18.4351Z" fill="white"/>
                <path d="M47.6714 2.09924C49.0202 2.09924 50.3305 2.09924 51.6022 2.09924C51.9876 2.09924 51.9491 2.36641 52.0262 2.55725C52.874 6.60305 53.876 10.6489 54.5697 14.7328C54.5697 14.771 54.6082 14.8092 54.6467 14.8855C55.0707 12.8626 55.4946 10.8397 55.9185 8.8168C56.3809 6.71756 56.8819 4.61832 57.3444 2.48092C57.4215 2.21374 57.46 2.06107 57.7683 2.06107C59.04 2.06107 60.3118 2.06107 61.6992 2.06107C61.2752 3.54962 60.8899 4.96183 60.4659 6.37405C59.2713 10.6107 58.0766 14.8092 56.8819 19.0458C56.8049 19.3511 56.8049 19.6565 56.8049 19.9618C56.8049 22.3664 56.8049 24.7328 56.8049 27.1374C56.8049 27.6718 56.6892 27.8626 56.1112 27.8244C55.1477 27.7863 54.1457 27.7863 53.1823 27.8244C52.6813 27.8626 52.4886 27.7099 52.5272 27.1756C52.5657 25.7634 52.4886 24.3511 52.5657 22.9771C52.7198 20.6489 52.2574 18.4351 51.6408 16.2214C50.292 11.6412 49.0588 7.0229 47.7485 2.40458C47.7099 2.36641 47.7099 2.25191 47.6714 2.09924Z" fill="white"/>
                <path d="M93.647 16.7176C93.647 20.229 93.647 23.7405 93.647 27.2519C93.647 27.7863 93.4929 27.9008 92.9919 27.9008C92.0284 27.8626 91.1035 27.8626 90.1401 27.9008C89.6005 27.9389 89.4464 27.7481 89.4464 27.2137C89.4849 21.3359 89.4464 15.458 89.4464 9.58015C89.4464 8.43511 89.4464 7.25191 89.4464 6.10687C89.4464 5.68702 89.3693 5.53435 88.9069 5.53435C87.8278 5.57252 86.7873 5.53435 85.7082 5.57252C85.3229 5.57252 85.1687 5.53435 85.1687 5.07633C85.2072 4.27481 85.2072 3.47328 85.1687 2.63359C85.1687 2.25191 85.2458 2.09924 85.6697 2.09924C89.562 2.09924 93.4543 2.09924 97.3466 2.09924C97.7706 2.09924 97.8862 2.21374 97.8476 2.59542C97.8091 3.43511 97.8091 4.27481 97.8476 5.07633C97.8476 5.41985 97.732 5.53435 97.3852 5.49618C96.3832 5.45801 95.3427 5.53435 94.3407 5.45801C93.7626 5.41985 93.5699 5.57252 93.5699 6.18321C93.647 9.73282 93.647 13.2443 93.647 16.7176Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="136" height="30" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
