import React from 'react';

interface IProps {}

export const AudioIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 6.33333V9.66666C0.5 10.125 0.875 10.5 1.33333 10.5H3.83333L6.575 13.2417C7.1 13.7667 8 13.3917 8 12.65V3.34166C8 2.59999 7.1 2.22499 6.575 2.74999L3.83333 5.49999H1.33333C0.875 5.49999 0.5 5.87499 0.5 6.33333ZM11.75 7.99999C11.75 6.52499 10.9 5.25833 9.66667 4.64166V11.35C10.9 10.7417 11.75 9.47499 11.75 7.99999ZM9.66667 1.70833V1.87499C9.66667 2.19166 9.875 2.46666 10.1667 2.58333C12.3167 3.44166 13.8333 5.54999 13.8333 7.99999C13.8333 10.45 12.3167 12.5583 10.1667 13.4167C9.86667 13.5333 9.66667 13.8083 9.66667 14.125V14.2917C9.66667 14.8167 10.1917 15.1833 10.675 15C13.5 13.925 15.5 11.2 15.5 7.99999C15.5 4.79999 13.5 2.07499 10.675 0.999995C10.1917 0.808328 9.66667 1.18333 9.66667 1.70833Z" fill="white"/>
        </svg>
    );
};
