export const APP_SELECTOR: string = '#root';
export const CANVAS_WRAPPER_SELECTOR: string = '.main-section__canvas-wrapper';
export const DEFAULT_CANVAS_PADDING: number = 30;
export const DEFAULT_FRAME_RATE: number = 60;
export const NAV_BAR_WIDTH: number = 326;
export const TOP_MENU_HEIGHT: number = 60;
export const SCENES_HEIGHT: number = 80;
export const DEFAULT_RATIO: number = 16 / 9;
export const DEFAULT_TIME_SLICE: number = 100;
export const DEFAULT_VIDEO_BITS_PER_SECOND: number = 2500000;
export const DEFAULT_AUDIO_BITS_PER_SECOND: number = 128000;
export const DEFAULT_MIME_TYPE: string = 'video/webm';
export const DEFAULT_CODEC: string = 'codecs=h264';
export const DEFAULT_CANVAS_BG_COLOR: string = '#161C21';
export const STORAGE_NAME_LABEL: string = 'STORAGE_NAME_LABEL';
export const STORAGE_CAMERA_LABEL: string = 'STORAGE_CAMERA_LABEL';
export const STORAGE_MICRO_LABEL: string = 'STORAGE_MICRO_LABEL';
