import { fabric } from 'fabric';
import { ISlideShowOptions } from './slide-show';

export enum LayerTypes {
    Text = 'Text',
    Image = 'Image',
    Camera = 'Camera',
    Audio = 'Audio',
    CaptureScreen = 'CaptureScreen',
    SlideShow = 'SlideShow',
}

export enum DeviceTypes {
    Video = 'videoinput',
    Audio = 'audioinput',
    AudioOutput = 'audiooutput',
}

export enum ImageFormats {
    PNG = 'png',
    JPG = 'jpg',
}

export interface ISize {
    width?: number;
    height?: number;
}

export interface IPosition {
    left: number;
    top: number;
}

export interface IAudioSource {
    id: string;
    deviceId: string;
    stream: MediaStream;
    volume: number;
    lastVolume: number;
    name: string;
    sceneId: string
}

export interface IImageSettings {
    image: string;
}

export interface ITextSettings {
    text: string;
    color: string;
    fontFamily: string;
}

export interface ICameraSettings {
    deviceId: string;
}

export interface ISlideShowSettings extends ISlideShowOptions {

}

export interface IScreenCaptureSettings {

}

export interface ILayer {
    id: string;
    isHidden: boolean;
    isLocked: boolean;
    index: number;
    zIndex: number;
    name: string;
    type: LayerTypes;
    fabricNode?: fabric.Image | fabric.Textbox;
    settings?: IImageSettings | ITextSettings | ICameraSettings | ISlideShowSettings | IScreenCaptureSettings | {};
    width?: number;
    height?: number;
    sceneId: string;
    left: number;
    top: number;
}

export interface IScene {
    id: string;
    name: string;
    layers: ILayer[];
    preview: string;
    lastSelectedLayer: ILayer | null;
    audioSources: IAudioSource[];
    isStartingScene: boolean;
    projectId: string;
    index: number;
}

export interface IProject {
    id: string;
    userId: string;
    name: string;
    scenes: IScene[];
    lastSelectedScene: string;
}

export interface IImage {
    userId: string;
    src: string;
    id: string;
}

export interface IUser {
    id: string;
    lastSelectedProject: string;
    name: string;
    projects: IProject[];
    images: IImage[];
}

export enum PatternsEnum {
    OneUser = 'OneUser',
    TwoUsers = 'TwoUsers',
    ThreeUsers = 'ThreeUsers',
    TwoUsersOneSmall = 'TwoUsersOneSmall',
    FiveUsers = 'FiveUsers',
    SixUsers = 'SixUsers',
}

export enum RolesEnum {
    Admin = 'admin',
    User = 'user',
}

export interface IUserConnection {
    id: string;
    stream: MediaStream;
    name: string;
    userRole: string;
    isMuted: boolean;
}

export enum LogoPositionsEnum {
    TopLeft = 'TopLeft',
    TopRight = 'TopRight',
    BottomLeft = 'BottomLeft',
    BottomRight = 'BottomRight',
}
