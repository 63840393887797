import { action, observable, makeObservable } from 'mobx';
import { Locales } from '@i18n';
import { Base } from './base-store';

export class AppStore extends Base {
    locale: Locales = Locales.English;
    isUserAuthorized: boolean = true;
    currentUserId: string = '';
    isSimpleMode: boolean = true;

    constructor(rootStore) {
        super(rootStore);

        const searchItems = location.search.slice(1).split('&');

        searchItems.forEach((searchItem: string) => {
            const [name, value] = searchItem.split('=');

            if (name === 'userId') {
                this.currentUserId = value;
            }
        });

        this.isUserAuthorized = !!this.currentUserId;

        makeObservable(this, {
            locale: observable,
            changeLocale: action,
        });
    }

    changeLocale = (locale: Locales) => {
        this.locale = locale;
    };
}
