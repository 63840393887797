import React from 'react';

interface IProps {}

export const UserIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0.666656C6.64002 0.666656 0.666687 6.63999 0.666687 14C0.666687 21.36 6.64002 27.3333 14 27.3333C21.36 27.3333 27.3334 21.36 27.3334 14C27.3334 6.63999 21.36 0.666656 14 0.666656ZM14 4.66666C16.2134 4.66666 18 6.45332 18 8.66666C18 10.88 16.2134 12.6667 14 12.6667C11.7867 12.6667 10 10.88 10 8.66666C10 6.45332 11.7867 4.66666 14 4.66666ZM14 23.6C10.6667 23.6 7.72002 21.8933 6.00002 19.3067C6.04002 16.6533 11.3334 15.2 14 15.2C16.6534 15.2 21.96 16.6533 22 19.3067C20.28 21.8933 17.3334 23.6 14 23.6Z" fill="#748499"/>
        </svg>
    );
};
