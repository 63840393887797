import React from 'react';

interface IProps {}

export const RTMPIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="131" height="30" viewBox="0 0 131 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.1954 29.3957H24.7724L18.8512 18.8201C20.4186 18.2734 21.7683 17.2806 22.9002 15.8417C24.0613 14.3741 24.6418 12.4892 24.6418 10.187C24.6418 7.42446 23.742 5.13669 21.9424 3.32374C20.1428 1.51079 17.6757 0.604313 14.5409 0.604313H-0.000732422V29.3957H7.4878V19.7266H11.3191L16.1954 29.3957ZM15.9777 12.4317C15.2811 12.9784 14.4248 13.2518 13.409 13.2518H7.4878V7.07914H13.409C14.4248 7.07914 15.2811 7.35252 15.9777 7.89928C16.6743 8.44604 17.0226 9.19424 17.0226 10.1439C17.0226 11.1223 16.6743 11.8849 15.9777 12.4317Z" fill="white"/>
            <path d="M88.9618 29.3957H96.4504V0.604313H86.0448L79.9495 16.2302L73.8977 0.604313H63.4921V29.3957H70.9807V10.6619L78.295 29.3957H81.6039L88.9618 10.6619V29.3957Z" fill="white"/>
            <path d="M39.7023 29.3957H47.0909V7.07914H55.0808V0.604313H31.6694V7.07914H39.7023V29.3957Z" fill="#748499"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M106.686 29.3957H114.075V19.7266H121.034C124.127 19.7266 126.561 18.8201 128.336 17.0072C130.112 15.1655 131 12.8921 131 10.187C131 7.45324 130.112 5.17985 128.336 3.3669C126.561 1.52518 124.127 0.604313 121.034 0.604313H106.686V29.3957ZM122.494 12.4317C121.864 12.9784 121.048 13.2518 120.046 13.2518H114.075V7.07914H120.046C121.048 7.07914 121.864 7.35252 122.494 7.89928C123.153 8.44604 123.482 9.20863 123.482 10.187C123.482 11.1367 123.153 11.8849 122.494 12.4317Z" fill="#748499"/>
        </svg>

    );
};
