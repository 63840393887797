import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { GuestStore, UsersConferenceStore, UserMediaStore } from '@services';
import { SettingsSection, UserItem, PurpleButton, SecondaryButton, SettingsModal } from '@components';
import { Routes, RolesEnum } from '@common-types';
import { TextLogoIcon, AudioIcon, AudioOffIcon, MicroOffIcon } from '@assets/icons';
import { StreamerVideo } from '../streamer-video';
import './styles.scss';

interface IProps extends RouteComponentProps {
    GuestStore?: GuestStore;
    UsersConferenceStore?: UsersConferenceStore;
    UserMediaStore?: UserMediaStore;
}

@inject('GuestStore', 'UsersConferenceStore', 'UserMediaStore')
@observer
class GuestComponent extends React.Component<IProps> {
    youVideoRef: React.RefObject<HTMLVideoElement> = React.createRef();

    async componentDidMount(): Promise<void> {
        const {selectedMicro, selectedCamera} = this.props.UserMediaStore!;
        const {init, setCurrentCamera, setCurrentMicro} = this.props.GuestStore!;

        await init(this.youVideoRef.current);
        setCurrentCamera(selectedCamera ? selectedCamera.deviceId : null);
        setCurrentMicro(selectedMicro ? selectedMicro.deviceId : null);

        setTimeout(() => {
            this.join();
        }, 1000);
    }

    join = async () => {
        try {
            const { getResultedMediaStream, muteByAdmin } = this.props.GuestStore!;
            const { start } = this.props.UsersConferenceStore!;
            const { name } = this.props.UserMediaStore!;
            const paramsArr = location.search.slice(1).split('&');
            let roomId = '';

            paramsArr.forEach((param: string) => {
                const [name, value] = param.split('=');

                if (name.toLowerCase() === 'roomid' && value) {
                    roomId = value;
                }
            });

            if (roomId) {
                const resultStream = await getResultedMediaStream();

                start(resultStream, name, roomId, false, muteByAdmin);
            }
        } catch (error) {
            console.error(error);
        }
    };

    leave = () => {
        this.props.history.push(`${Routes.SelectMedia}${location.search}`);

        setTimeout(() => {
            location.reload();
        });
    };

    getStreamerStream = () => {
        const { streams } = this.props.UsersConferenceStore;
        let streamerStream: MediaStream = null;

        Object.keys(streams).forEach((key: string) => {
            if (streams[key] && streams[key].userRole === RolesEnum.Admin) {
                streamerStream = streams[key].stream;
            }
        });

        return streamerStream;
    };

    onSettingsSave = async (
        camera: string,
        micro: string,
        audio: string,
    ) => {
        const { updateMicro, updateCamera } = this.props.GuestStore!;

        await updateCamera(camera);
        await updateMicro(micro);
    };

    render(): React.ReactNode {
        const {
            addScreenShare,
            toggleSound,
            toggleVideo,
            isMicroMuted,
            isVideoMuted,
            isVideoOff,
            isMicroOff,
            isAudioMuted,
            isAudioOff,
            toggleAudio,
            isSettingsModalShown,
            openSettingsModal,
            closeSettingsModal,
            currentMicro,
            currentCamera,
            isMutedByAdmin,
            updateId,
        } = this.props.GuestStore;
        const { streams } = this.props.UsersConferenceStore;
        const streamerStream = this.getStreamerStream();

        return (
            <div className='guest'>
                <div className="guest__left">
                    <div className="guest__header">
                        <div className="guest__logo">
                            <TextLogoIcon />
                        </div>
                        <div className="guest__logo">
                            <SecondaryButton onClick={this.leave}>
                                <FormattedMessage id='leave.broadcast' />
                            </SecondaryButton>
                        </div>
                    </div>
                    <div className="guest__main-section">
                        {
                            isMutedByAdmin && (
                                <div className='guest__muted-warning'>
                                    <FormattedMessage id='you.are.muted.by.admin' />
                                </div>
                            )
                        }
                        <div className="guest__video-wrapper">
                            {
                                streamerStream && (
                                    <StreamerVideo stream={streamerStream} isAudioMuted={isAudioMuted} />
                                )
                            }
                        </div>
                        <div className="guest__settings">
                            <SettingsSection
                                onScreenShareClick={addScreenShare}
                                onCameraClick={toggleVideo}
                                onMicroClick={toggleSound}
                                onAudioClick={toggleAudio}
                                onSettingsClick={openSettingsModal}
                                buttonOptions={{
                                    isVideoMuted,
                                    isMicroMuted,
                                    isVideoOff,
                                    isMicroOff,
                                    isAudioMuted,
                                    isAudioOff,
                                }}
                            />
                        </div>
                    </div>
                    <div className="guest__users">
                        <div className="guest__you-wrapper">
                            <div className="guest__you-top">
                                <div className="guest__you-name">
                                    <FormattedMessage id='you' />
                                </div>
                                <div className="guest__you-signal">

                                </div>
                            </div>
                            <div className="guest__you-bottom">
                                <video
                                    className="guest__you-video"
                                    ref={this.youVideoRef}
                                    muted={true}
                                />
                                <div className="guest__you-audio-icon" onClick={toggleAudio}>
                                    {isAudioMuted ? <AudioOffIcon /> : <AudioIcon />}
                                </div>
                                {
                                    isMicroMuted && (
                                        <Tooltip title={<FormattedMessage id="you.are.muted" />}>
                                            <div className="guest__you-micro-icon">
                                                <MicroOffIcon />
                                            </div>
                                        </Tooltip>
                                    )
                                }
                            </div>
                        </div>
                        {
                            Object.keys(streams).map((connectionId: string) => {
                                const { stream, name, id } = streams[connectionId];

                                return (
                                    <UserItem
                                        soundOnly={true}
                                        key={id}
                                        stream={stream}
                                        name={name}
                                        signal={100}
                                        soundOff={isAudioMuted}
                                    />
                                );
                            })
                        }
                        <div className="guest__backstage-message">
                            <div className="guest__backstage-header">
                                <FormattedMessage id='you.backstage' />
                            </div>
                            <div className="guest__backstage-text">
                                <FormattedMessage id='backstage.message' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="guest__right">

                </div>
                {
                    isSettingsModalShown && (
                        <SettingsModal
                            closeModal={closeSettingsModal}
                            showModal={isSettingsModalShown}
                            selectedCamera={currentCamera}
                            selectedMicro={currentMicro}
                            saveDevices={this.onSettingsSave}
                        />
                    )
                }
            </div>
        );
    }
}

export const Guest = withRouter(GuestComponent);
