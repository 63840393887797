import { v4 } from 'uuid';
import { io } from 'socket.io-client';


export function init(remoteVideo: HTMLVideoElement) {
    const socket = io();
    let answersFrom = {};
    let offer;
    const sessionDescription = window.RTCSessionDescription;

    const pc = new RTCPeerConnection({
        iceServers: [{
            urls: "stun:stun.services.mozilla.com",
            username: "somename",
            credential: "somecredentials"
        }]
    });

    socket.on('add-users', function (data) {
        console.log('add-users', data);
        for (var i = 0; i < data.users.length; i++) {
            const id = data.users[i];

            createOffer(id);
        }
    });

    socket.on('remove-user', function (id) {
        console.log('remove-users', id);
        console.log('remove-user');
    });

    socket.on('answer-made', async function (data) {
        console.log('answer-made');
        try {
            await pc.setRemoteDescription(new sessionDescription(data.answer));

            if (!answersFrom[data.socket]) {
                // createOffer(data.socket);
                answersFrom[data.socket] = true;
            }
        } catch (e) {
            error(e);
        }
    });

    socket.on('offer-made', async function (data) {
        console.log('offer-made');
        try {
            offer = data.offer;

            await pc.setRemoteDescription(new sessionDescription(data.offer));
            const answer = await pc.createAnswer();
            await pc.setLocalDescription(new sessionDescription(answer));

            socket.emit('make-answer', {
                answer: answer,
                to: data.socket
            });
        } catch (e) {
            error(e);
        }
    });

    async function createOffer(id) {
        try {
            const offer = await pc.createOffer();
            await pc.setLocalDescription(new sessionDescription(offer));

            socket.emit('make-offer', {
                offer: offer,
                to: id
            });
        } catch (e) {
            error(e);
        }
    }

    function error(err) {
        console.error('Error', err);
    }

    pc.ontrack = function (event) {
        console.log('ontrack');
        console.log('event', event);
        console.log('streams', event.streams);
        remoteVideo.srcObject = event.streams[0];
        remoteVideo.play();
    };

    function start(stream, roomId = v4(), isCreator = true) {
        stream.getTracks().forEach(function(track) {
            pc.addTrack(track, stream);
        });

        console.log('roomId', roomId);
        console.log('isCreator', isCreator);

        if (isCreator) {
            alert(`${location.origin}/?roomid=${roomId}`);
            socket.emit('create-room', roomId);
        } else {
            socket.emit('start', roomId);
        }
    }

    return {
        start
    };
}
