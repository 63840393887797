import React from 'react';
import { fabric } from 'fabric';
import {
    AudioOutlined,
    BlockOutlined,
    FontColorsOutlined,
    PictureOutlined,
    UpSquareOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {
    ILayer,
    IPosition,
    ISize,
    LayerTypes,
    ICameraSettings,
    IImageSettings,
    ITextSettings,
    ISlideShowSettings,
    IScreenCaptureSettings,
} from '@common-types';

export const getLayerIcon = (layer: LayerTypes): React.ReactElement => {
    switch (layer) {
        case LayerTypes.Image: {
            return (
                <PictureOutlined />
            );
        }
        case LayerTypes.Camera: {
            return (
                <VideoCameraOutlined />
            );
        }
        case LayerTypes.Text: {
            return (
                <FontColorsOutlined />
            );
        }
        case LayerTypes.Audio: {
            return (
                <AudioOutlined />
            );
        }
        case LayerTypes.CaptureScreen: {
            return (
                <UpSquareOutlined />
            );
        }
        case LayerTypes.SlideShow: {
            return (
                <BlockOutlined />
            );
        }
    }
};

export const getNewLayer = (
    id: string,
    name: string,
    type: LayerTypes,
    sceneId: string,
    index: number,
    zIndex: number,
    size: ISize,
    position: IPosition,
    settings: (
        ITextSettings |
        IImageSettings |
        ICameraSettings |
        IScreenCaptureSettings |
        ISlideShowSettings |
        {}
    ) = {},
    fabricNode: fabric.Textbox | fabric.Image = null
): ILayer => {
    return {
        id,
        isHidden: false,
        isLocked: false,
        name,
        type,
        top: position.top,
        left: position.left,
        width: size.width,
        height: size.height,
        settings,
        index,
        fabricNode,
        sceneId,
        zIndex,
    };
};
