import React from 'react';

interface IProps {}

export const SettingsIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1917 9.81667C15.225 9.55 15.25 9.28334 15.25 9.00001C15.25 8.71667 15.225 8.45001 15.1917 8.18334L16.95 6.80834C17.1083 6.68334 17.15 6.45834 17.05 6.275L15.3833 3.39167C15.2833 3.20834 15.0583 3.14167 14.875 3.20834L12.8 4.04167C12.3667 3.70834 11.9 3.43334 11.3917 3.22501L11.075 1.01667C11.05 0.816672 10.875 0.666672 10.6667 0.666672H7.33332C7.12499 0.666672 6.94999 0.816672 6.92499 1.01667L6.60832 3.22501C6.09999 3.43334 5.63332 3.71667 5.19999 4.04167L3.12499 3.20834C2.93332 3.13334 2.71666 3.20834 2.61666 3.39167L0.949988 6.275C0.841655 6.45834 0.891655 6.68334 1.04999 6.80834L2.80832 8.18334C2.77499 8.45001 2.74999 8.72501 2.74999 9.00001C2.74999 9.27501 2.77499 9.55 2.80832 9.81667L1.04999 11.1917C0.891655 11.3167 0.849988 11.5417 0.949988 11.725L2.61666 14.6083C2.71666 14.7917 2.94165 14.8583 3.12499 14.7917L5.19999 13.9583C5.63332 14.2917 6.09999 14.5667 6.60832 14.775L6.92499 16.9833C6.94999 17.1833 7.12499 17.3333 7.33332 17.3333H10.6667C10.875 17.3333 11.05 17.1833 11.075 16.9833L11.3917 14.775C11.9 14.5667 12.3667 14.2833 12.8 13.9583L14.875 14.7917C15.0667 14.8667 15.2833 14.7917 15.3833 14.6083L17.05 11.725C17.15 11.5417 17.1083 11.3167 16.95 11.1917L15.1917 9.81667ZM8.99999 11.9167C7.39165 11.9167 6.08332 10.6083 6.08332 9.00001C6.08332 7.39167 7.39165 6.08334 8.99999 6.08334C10.6083 6.08334 11.9167 7.39167 11.9167 9.00001C11.9167 10.6083 10.6083 11.9167 8.99999 11.9167Z" fill="white"/>
        </svg>
    );
};
