import React from 'react';
import { IImage } from '@common-types';
import './styles.scss';

interface IProps {
    images: IImage[];
    selectedSrc: string;
    addNewImage: (img: IImage) => void;
    selectImage: (img: IImage) => void;
}

export class ImagesList extends React.Component<IProps> {
    render(): React.ReactNode {
        const { images, selectImage, selectedSrc } = this.props;

        return (
            <div className='images-list'>
                {
                    images.map((img: IImage) => {
                        const isSelected: boolean = img.src === selectedSrc;

                        return (
                            <div
                                className={`images-list__item ${isSelected ? 'selected' : ''}`}
                                key={img.id}
                                onClick={() => selectImage(img)}
                            >
                                <img className='images-list__img' src={img.src} />
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}
