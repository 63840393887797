import { action, makeObservable, observable } from 'mobx';
import { fabric } from 'fabric';
import { v4 } from 'uuid';
import { DEFAULT_CANVAS_BG_COLOR, DEFAULT_FRAME_RATE } from '@constants';
import {
    IAudioSource,
    IImage,
    ILayer,
    ImageFormats,
    IPosition,
    IProject,
    IScene,
    ISize,
    ISlideShowOptions,
    ISlideShowSettings,
    ITextSettings,
    IUser,
    LayerTypes,
    PatternsEnum,
} from '@common-types';
import {
    createImageNode,
    getCanvasSize,
    getEmptyScene,
    getLayerDefaultPosition,
    getLayerDefaultSettings,
    getLayerDefaultSize,
    getNewLayer,
} from '@utils';
import { Base } from './base-store';
import { SlideShow, Websockets } from '../services';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';

export class StreamStore extends Base {
    remoteVideo: HTMLVideoElement;
    projects: IProject[] = [];
    ws: Websockets;
    canvas: fabric.Canvas;
    streamCanvas: fabric.Canvas;
    mainStreamNode: fabric.Image;
    selectedProject: IProject = null;
    selectedScene: IScene = null;
    selectedLayer: ILayer | null = null;
    isImageDrawerOpen: boolean = false;
    isCameraDrawerOpen: boolean = false;
    isAudioDrawerOpen: boolean = false;
    isSlideShowDrawerOpen: boolean = false;
    isSlideShowFormOpen: boolean = false;
    isMusicDrawerOpen: boolean = false;
    isStreaming: boolean = false;
    parsedUserId: string = '';
    slideShows: SlideShow[] = [];
    selectedSlideShow: SlideShow | null = null;
    images: IImage[] = [];
    streamMap: { [key: string]: MediaStream } = {};
    controlUpdateId: string = v4();
    currentUser: IUser = null;

    constructor(rootStore) {
        super(rootStore);

        this.ws = new Websockets(this);

        makeObservable(this, {
            projects: observable,
            controlUpdateId: observable,
            selectedProject: observable,
            isImageDrawerOpen: observable,
            isCameraDrawerOpen: observable,
            isAudioDrawerOpen: observable,
            selectedLayer: observable,
            selectedScene: observable,
            isSlideShowDrawerOpen: observable,
            selectedSlideShow: observable,
            isSlideShowFormOpen: observable,
            isMusicDrawerOpen: observable,
            parsedUserId: observable,
            isStreaming: observable,
            selectScene: action,
            createEmptyScene: action,
            changeSceneName: action,
            deleteScene: action,
            deleteLayer: action,
            initialize: action,
            changeLayerName: action,
            openImageDrawer: action,
            closeImageDrawer: action,
            openCameraDrawer: action,
            closeCameraDrawer: action,
            openAudioDrawer: action,
            closeAudioDrawer: action,
            addTextLayer: action,
            addImageLayer: action,
            addCameraLayer: action,
            setStreamStatus: action,
            addCaptureScreenLayer: action,
            addSlideShowLayer: action,
            moveLayerBack: action,
            moveLayerForward: action,
            openSlideShowDrawer: action,
            closeSlideShowDrawer: action,
            openMusicDrawer: action,
            closeMusicDrawer: action,
            startStream: action,
            stopStream: action,
            changeAudioSourceVolume: action,
            selectLayer: action,
            addNode: action,
            recalculateIndexes: action,
            dragLayerEnd: action,
            dragSceneEnd: action,
            openSlideShowForm: action,
            closeSlideShowForm: action,
            selectSlideShow: action,
            updateLayerPosition: action,
            createEmptyProject: action,
        });
    }

    selectSlideShow = (slideShow: SlideShow | null) => {
        this.selectedSlideShow = slideShow;
    };

    selectLayer = (layer: ILayer | null) => {
        this.selectedLayer = layer;
        this.selectedScene.lastSelectedLayer = layer;
    };

    openImageDrawer = () => this.isImageDrawerOpen = true;

    closeImageDrawer = () => this.isImageDrawerOpen = false;

    openCameraDrawer = () => this.isCameraDrawerOpen = true;

    closeCameraDrawer = () => this.isCameraDrawerOpen = false;

    openAudioDrawer = () => this.isAudioDrawerOpen = true;

    closeAudioDrawer = () => this.isAudioDrawerOpen = false;

    openSlideShowDrawer = () => this.isSlideShowDrawerOpen = true;

    closeSlideShowDrawer = () => {
        this.selectSlideShow(null);
        this.isSlideShowDrawerOpen = false;
    };

    openSlideShowForm = () => this.isSlideShowFormOpen = true;

    closeSlideShowForm = () => {
        this.isSlideShowFormOpen = false;
        this.selectSlideShow(null);
    };

    openMusicDrawer = () => this.isMusicDrawerOpen = true;

    closeMusicDrawer = () => this.isMusicDrawerOpen = false;

    selectScene = (scene: IScene) => {
        if (this.selectedScene) {
            this.canvas.discardActiveObject();
            this.selectedScene.preview = this.canvas.toDataURL({ format: ImageFormats.PNG });
            this.selectedScene.layers.forEach(({ fabricNode }) => {
                fabricNode.visible = false;
            });
        }

        scene.layers.forEach(({ fabricNode }) => {
            fabricNode.visible = true;
        });

        this.selectedScene = scene;

        if (this.selectedScene.lastSelectedLayer) {
            this.selectLayer(this.selectedScene.lastSelectedLayer);
        }
    };

    addAudioSourceToScene = (stream: MediaStream, name: string, deviceId: string): IAudioSource => {
        const source = this.selectedScene.audioSources.find((item: IAudioSource) => {
            return item.deviceId === deviceId;
        });

        if (!source) {
            const newSource: IAudioSource = {
                id: v4(),
                stream,
                deviceId,
                volume: 1,
                lastVolume: 1,
                name,
                sceneId: this.selectedScene.id,
            };

            this.selectedScene.audioSources.push(newSource);

            return newSource;
        }

        return source;
    };

    deleteAudioSourceFromScene = (id: string) => {
        const source = this.selectedScene.audioSources.find((source) => source.id !== id);

        if (source && source.stream) {
            source.stream.getAudioTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
        }

        this.selectedScene.audioSources = this.selectedScene.audioSources.filter((source) => source.id !== id);
    };

    changeSceneName = (scene: IScene, name: string) => {
        scene.name = name;
    };

    deleteScene = (scene: IScene) => {
        this.selectedProject.scenes = this.selectedProject.scenes.filter(({ id }) => {
            return id !== scene.id;
        });

        if (this.selectedScene.id === scene.id) {
            this.selectScene(this.selectedProject.scenes[0]);
        }
    };

    createEmptyScene = async (projectId: string, isStartingScene: boolean = false) => {
        try {
            const name: string = `${this.dependencies.fm('scene')} ${this.selectedProject.scenes.length + 1}`;
            const scene: IScene = getEmptyScene(
                name,
                this.selectedProject.scenes.length,
                projectId,
                isStartingScene,
            );

            this.selectedProject.scenes.push(scene);
            this.selectScene(this.selectedProject.scenes.find(({ id }) => id === scene.id));

            await fetch(`/api/scenes/${projectId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(scene),
            });
        } catch (error) {
            console.error(error);
        }
    };

    createEmptyProject = (userId: string = v4()) => {
        const name: string = `${this.dependencies.fm('project')} ${this.projects.length + 1}`;
        const project: IProject = {
            id: v4(),
            name,
            userId,
            scenes: [],
            lastSelectedScene: null,
        };

        this.projects.push(project);
        this.selectedProject = project;

        return project;
    };

    getInitializedLayers = async (layers: ILayer[], images: IImage[], scene: IScene): Promise<ILayer[]> => {
        const res = [];

        for (const layer of layers) {
            let fabricNode;
            const settings = JSON.parse((layer.settings as string) || '{}');

            if (layer.type === LayerTypes.Text) {
                fabricNode = new fabric.Textbox(this.dependencies.fm('default.text'), {
                    width: layer.width,
                    height: layer.height,
                    top: layer.top,
                    left: layer.left,
                    fill: '#ffffff',
                });
            } else if (layer.type === LayerTypes.Image) {
                const imgId = settings.image;
                const img = images.find(({ id }) => {
                    return id === imgId;
                });
                fabricNode = await createImageNode(img);
                fabricNode.scaleToWidth(200);
            } else if (layer.type === LayerTypes.Camera) {
                const video = document.createElement('video');
                video.width = 298;
                video.height = 168;
                video.muted = true;
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                    },
                    video: {
                        width: 298,
                        height: 168,
                        deviceId: settings.deviceId,
                    }
                });

                video.srcObject = stream;
                video.play();

                fabricNode = new fabric.Image(video, { objectCaching: false });
                fabricNode.scaleToWidth(200);
                this.streamMap[layer.id] = stream;

                const audioTracks = stream.getAudioTracks();

                if (audioTracks && audioTracks.length) {
                    const track = audioTracks[0];
                    const settings = track.getSettings();
                    const newSource: IAudioSource = {
                        id: v4(),
                        stream,
                        deviceId: settings.deviceId,
                        volume: 1,
                        lastVolume: 1,
                        name: track.label,
                        sceneId: scene.id,
                    };

                    scene.audioSources.push(newSource);
                }
            } else if (layer.type === LayerTypes.SlideShow) {
                const options = {
                    ...settings as ISlideShowOptions,
                    images: (settings as ISlideShowOptions).images.map((id) => {
                        return images.find((full) => {
                            // @ts-ignore
                            return id === full.id;
                        });
                    })
                };
                const slideShow = new SlideShow(this.canvas, layer.id);
                fabricNode = await slideShow.create({
                    ...options,
                });
                this.slideShows.push(slideShow);
            }

            if (layer.type !== LayerTypes.SlideShow) {
                this.canvas.add(fabricNode);
            }

            res.push({
                ...layer,
                settings,
                fabricNode,
            });
        }

        return res;
    };

    initialize = async (canvasInstance: HTMLCanvasElement, streamCanvas: HTMLCanvasElement, userId: string) => {
        this.parsedUserId = userId;
        const response = await fetch(`/api/users/${userId}`, {
            method: 'GET',
        });
        const result: IUser = await response.json() as IUser;
        this.currentUser = result;

        this.canvas = new fabric.Canvas(canvasInstance, {
            ...getCanvasSize(),
            backgroundColor: DEFAULT_CANVAS_BG_COLOR,
        });
        this.streamCanvas = new fabric.Canvas(streamCanvas, {
            width: 195,
            height: 110,
            backgroundColor: DEFAULT_CANVAS_BG_COLOR,
            selection: false,
        });

        const self = this;
        fabric.util.requestAnimFrame(function render() {
            self.canvas.renderAll();
            self.streamCanvas.renderAll();
            fabric.util.requestAnimFrame(render);
        });

        result.projects[0].scenes = result.projects[0].scenes.map((scene) => {
            return {
                ...scene,
                audioSources: [],
            };
        });

        this.images = result.images.map((img: IImage) => {
            return {
                ...img,
                src: `/images/${img.src}`, // todo util
            };
        });

        for (const scene of result.projects[0].scenes) {
            scene.layers = await this.getInitializedLayers(scene.layers, this.images, scene);
        }

        this.projects = result.projects;
        this.selectedProject = this.projects[0];
        this.selectScene(this.selectedProject.scenes.find(({ id }) => id === this.selectedProject.lastSelectedScene));

        
        this.selectedProject.scenes.forEach(({ layers }) => {
            layers.forEach((layer) => {
                layer.fabricNode.on('selected', () => {
                    this.selectLayer(layer);
                });
            });
        });

        this.recalculateIndexes();

        this.canvas.on('selection:cleared', () => {
            this.selectLayer(null);
        });

        const resize = action(() => {
            const { width, height } = getCanvasSize();
            const currentWidth = this.canvas.getWidth();
            const currentHeight = this.canvas.getHeight();

            const scale = width / currentWidth;

            this.canvas.setWidth(width);
            this.canvas.setHeight(height);
            this.canvas.calcOffset();

            this.canvas._objects.forEach((obj) => {
                if (obj && obj.scale) {
                    const objWidth = obj.getScaledWidth();

                    obj.scaleToWidth(objWidth * scale);
                    obj.set('top', obj.top * scale);
                    obj.set('left', obj.left * scale);
                    obj.setCoords();
                }
            });

            this.controlUpdateId = v4();
        });

        window.addEventListener('resize', resize);

        this.startCanvasStream();
    };

    testVideo;

    // создаем клип патх на нужную площадь, берем эелеминт растягивем во всю высоту ширину берем как у видео
    // потом задаем левт половину от ширины и все
    startCanvasStream = () => {
        // @ts-ignore
        const canvasStream: MediaStream = this.canvas.getElement().captureStream(DEFAULT_FRAME_RATE);
        const video = document.createElement('video');
        video.srcObject = canvasStream;
        video.muted = true;
        video.width = 1080;
        video.height = 608;
        const node = new fabric.Image(video, { left: 0, top: 0, objectCaching: false });
        node.scaleToWidth(this.streamCanvas.getWidth());
        this.streamCanvas.add(node);
        this.mainStreamNode = node;
        this.testVideo = video;
        (node.getElement() as HTMLVideoElement).play();
        node.center();
    };

    changeLayerPattern = (pattern: PatternsEnum) => {
        const canvasHalfWidth = this.streamCanvas.getWidth() / 2;
        const height = this.streamCanvas.getHeight();
        const canvasHalfHeight = height / 2;

        if (pattern === PatternsEnum.TwoUsers) {
            const clip1 = new fabric.Rect({
                width: canvasHalfWidth,
                height,
                top: 0,
                left: 0,
                absolutePositioned: true
            });
            const clip2 = new fabric.Rect({
                width: canvasHalfWidth,
                height: this.streamCanvas.getHeight(),
                top: 0,
                left: canvasHalfWidth,
                absolutePositioned: true
            });

            this.mainStreamNode.scaleToHeight(this.streamCanvas.getHeight());
            this.mainStreamNode.clipPath = clip1;

            const witdh = this.mainStreamNode.getScaledWidth();

            this.mainStreamNode.left = -((witdh - canvasHalfWidth) / 2);
            this.mainStreamNode.top = 0;
            this.mainStreamNode.setCoords();

            const node = new fabric.Image(this.testVideo, { left: 0, top: 0, objectCaching: false });
            node.scaleToHeight(this.streamCanvas.getHeight());
            node.clipPath = clip2;

            const witdh1 = node.getScaledWidth();

            node.top = 0;
            node.left = canvasHalfWidth - ((witdh1 - canvasHalfWidth) / 2);
            node.setCoords();
            this.streamCanvas.add(node);
            (node.getElement() as HTMLVideoElement).play();

        } else if (pattern === PatternsEnum.ThreeUsers) {
            const clip1 = new fabric.Rect({
                width: canvasHalfWidth,
                height,
                top: 0,
                left: 0,
                absolutePositioned: true
            });
            const clip2 = new fabric.Rect({
                width: canvasHalfWidth,
                height: canvasHalfHeight,
                top: 0,
                left: canvasHalfWidth + 3,
                absolutePositioned: true
            });
            const clip3 = new fabric.Rect({
                width: canvasHalfWidth,
                height: canvasHalfHeight,
                top: canvasHalfHeight + 3,
                left: canvasHalfWidth + 3,
                absolutePositioned: true
            });

            this.mainStreamNode.scaleToHeight(this.streamCanvas.getHeight());
            this.mainStreamNode.clipPath = clip1;
            const witdh = this.mainStreamNode.getScaledWidth();
            this.mainStreamNode.left = -((witdh - canvasHalfWidth) / 2);
            this.mainStreamNode.top = 0;
            this.mainStreamNode.setCoords();


            const node1 = new fabric.Image(this.testVideo, { left: 0, top: 0, objectCaching: false });
            node1.scaleToHeight(this.streamCanvas.getHeight() / 2);
            node1.clipPath = clip2;

            const witdh1 = node1.getScaledWidth();

            node1.top = 0;
            node1.left = canvasHalfWidth - ((witdh1 - canvasHalfWidth) / 2);
            node1.setCoords();
            this.streamCanvas.add(node1);
            (node1.getElement() as HTMLVideoElement).play();


            const node2 = new fabric.Image(this.testVideo, { left: 0, top: 0, objectCaching: false });
            node2.scaleToHeight(this.streamCanvas.getHeight() / 2);
            node2.clipPath = clip3;

            const witdh2 = node2.getScaledWidth();

            node2.top = canvasHalfHeight;
            node2.left = canvasHalfWidth - ((witdh2 - canvasHalfWidth) / 2);
            node2.setCoords();
            this.streamCanvas.add(node2);
            (node2.getElement() as HTMLVideoElement).play();

        } else if (pattern === PatternsEnum.FiveUsers) {

        }
    };

    addTextLayer = () => {
        const position: IPosition = getLayerDefaultPosition(LayerTypes.Text);
        const size: ISize = getLayerDefaultSize(LayerTypes.Text);
        const node = new fabric.Textbox(this.dependencies.fm('default.text'), {
            ...position,
            ...size,
            fill: '#ffffff',
        });
        const layer = getNewLayer(
            v4(),
            this.dependencies.fm('text'),
            LayerTypes.Text,
            this.selectedScene.id,
            0,
            this.selectedScene.layers.length,
            size,
            position,
            getLayerDefaultSettings(LayerTypes.Text),
            node,
        );

        this.addNode(layer);
    };

    addImageLayer = (img: IImage) => {
        const position: IPosition = getLayerDefaultPosition(LayerTypes.Image);
        const size: ISize = getLayerDefaultSize(LayerTypes.Image);

        fabric.Image.fromURL(img.src, (node) => {
            node.scaleToWidth(size.width);
            const layer = getNewLayer(
                v4(),
                this.dependencies.fm('image'),
                LayerTypes.Image,
                this.selectedScene.id,
                0,
                this.selectedScene.layers.length,
                size,
                position,
                {
                    image: img.id,
                },
                node,
            );

            this.addNode(layer);
        });

    };

    addCameraLayer = (label: string, video: HTMLVideoElement, stream: MediaStream, deviceId: string): void => {
        const position: IPosition = getLayerDefaultPosition(LayerTypes.Camera);
        const size: ISize = getLayerDefaultSize(LayerTypes.Camera);
        const node = new fabric.Image(video, { ...position, objectCaching: false });
        node.scaleToWidth(size.width);
        const layer = getNewLayer(
            v4(),
            label || this.dependencies.fm('camera'),
            LayerTypes.Camera,
            this.selectedScene.id,
            0,
            this.selectedScene.layers.length,
            size,
            position,
            { deviceId },
            node,
        );

        this.streamMap[layer.id] = stream;
        this.addNode(layer);
        (node.getElement() as HTMLVideoElement).play();
    };

    addCaptureScreenLayer = async (): Promise<void> => {
        const position: IPosition = getLayerDefaultPosition(LayerTypes.CaptureScreen);
        const size: ISize = getLayerDefaultSize(LayerTypes.CaptureScreen);
        const video = document.createElement('video');

        // @ts-ignore
        const stream = await navigator.mediaDevices.getDisplayMedia(
            {
                video: {
                    cursor: 'always',
                    width: 1920,
                    height: 1080,
                },
                audio: true,
            }
        );

        video.width = 1920;
        video.height = 1080;
        video.srcObject = stream;
        video.muted = true;

        const node = new fabric.Image(video, {
            ...position,
            objectCaching: false,
        });
        const name = this.dependencies.fm('capture.screen');

        node.scaleToWidth(size.width);

        const layer = getNewLayer(
            v4(),
            name,
            LayerTypes.CaptureScreen,
            this.selectedScene.id,
            0,
            this.selectedScene.layers.length,
            size,
            position,
            getLayerDefaultSettings(LayerTypes.CaptureScreen),
            node,
        );

        this.streamMap[layer.id] = stream;
        this.addNode(layer);
        (node.getElement() as HTMLVideoElement).play();

        const audioTracks = stream.getAudioTracks();
        let source: IAudioSource;

        if (audioTracks && audioTracks.length) {
            const track = audioTracks[0];
            const settings = track.getSettings();
            source = this.addAudioSourceToScene(stream, name, settings.deviceId); // todo name
        }

        stream.getVideoTracks()[0].onended = () => {
            this.deleteLayer(layer);

            // todo delete source here
            if (source) {
                const scene = this.selectedProject.scenes.find(({ id }) => id === layer.sceneId)
            }
        };
    };

    addSlideShowLayer = async (options: ISlideShowOptions): Promise<void> => { // todo scale
        const position: IPosition = getLayerDefaultPosition(LayerTypes.SlideShow);
        const size: ISize = getLayerDefaultSize(LayerTypes.SlideShow);
        const name = this.dependencies.fm('slide.show');
        const id = v4();
        const slideShow = new SlideShow(this.canvas, id);
        const node = await slideShow.create({
            ...options,
        });
        const layer = getNewLayer(
            id,
            name,
            LayerTypes.SlideShow,
            this.selectedScene.id,
            0,
            this.selectedScene.layers.length,
            size,
            position,
            options,
            node,
        );

        this.addNode(layer);
        this.slideShows.push(slideShow);
    };

    deleteLayer = (layer: ILayer) => {
        try {
            const scene = this.selectedProject.scenes.find(({ id }) => {
                return layer.sceneId === id;
            });

            if (scene) {
                scene.layers = scene.layers.filter(({ id }) => {
                    return id !== layer.id;
                });

                if (layer.type === LayerTypes.SlideShow) {
                    const slideShow = this.slideShows.find(({ layerId }) => {
                        return layerId === layer.id;
                    });

                    if (slideShow) {
                        slideShow.stop();
                    }
                }

                if (layer.type === LayerTypes.CaptureScreen && this.streamMap[layer.id]) {
                    this.streamMap[layer.id].getTracks().forEach((track: MediaStreamTrack) => {
                        track.stop();
                    });
                }

                this.canvas.discardActiveObject();
                this.canvas.remove(layer.fabricNode);

                fetch(`/api/layers/${layer.id}`, {
                    method: 'DELETE',
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    changeLayerName = (layer: ILayer, name: string) => {
        layer.name = name;
    };

    moveLayerBack = (layer: ILayer) => {
        if (layer.index > 0) {
            const startIndex = layer.index;
            const endIndex = layer.index - 1;

            if (startIndex === endIndex) {
                return;
            }

            const { layers } = this.selectedScene;
            const [ removed ] = layers.splice(startIndex, 1);
            layers.splice(endIndex, 0, removed);
            this.recalculateIndexes();
        }
    };

    moveLayerForward = (layer: ILayer) => {
        if (layer.index < this.selectedScene.layers.length - 1) {
            const startIndex = layer.index;
            const endIndex = layer.index + 1;

            if (startIndex === endIndex) {
                return;
            }

            const { layers } = this.selectedScene;
            const [ removed ] = layers.splice(startIndex, 1);
            layers.splice(endIndex, 0, removed);
            this.recalculateIndexes();
        }
    };

    dragLayerEnd = (result: DropResult, provided: ResponderProvided) => {
        if (!result.destination) {
            return;
        }

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        if (startIndex === endIndex) {
            return;
        }

        const { layers } = this.selectedScene;
        const [ removed ] = layers.splice(startIndex, 1);
        layers.splice(endIndex, 0, removed);
        this.selectLayer(removed);
        this.recalculateIndexes();
    };

    dragSceneEnd = (result: DropResult, provided: ResponderProvided) => {
        if (!result.destination) {
            return;
        }

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        if (startIndex === endIndex) {
            return;
        }

        const [ removed ] = this.selectedProject.scenes.splice(startIndex, 1);
        this.selectedProject.scenes.splice(endIndex, 0, removed);

        for (let i = 0; i < this.selectedProject.scenes.length; i++) {
            this.selectedProject.scenes[i].index = i;
        }
    };

    changeAudioSourceVolume = (id: string, volume: number) => {
        const audioSource: IAudioSource = this.selectedScene.audioSources.find((source: IAudioSource) => source.id === id);

        if (audioSource) {
            audioSource.lastVolume = audioSource.volume;
            audioSource.volume = volume;
        }
    };

    startStream = async () => {
        this.isStreaming = true;
        // @ts-ignore
        const canvasStream: MediaStream = this.streamCanvas.getElement().captureStream(DEFAULT_FRAME_RATE);
        const audioContext = new AudioContext();
        const destination = audioContext.createMediaStreamDestination();
        const audioSources = [];
        let audioTracks: number = 0;

        this.selectedScene.audioSources.forEach(({ stream }) => {
            if (stream && stream.getAudioTracks() && stream.getAudioTracks().length) {
                audioTracks++;
                const audio = audioContext.createMediaStreamSource(stream);

                audioSources.push(audio);
                audio.connect(destination);
            }
        });

        if (audioTracks > 0) {
            canvasStream.addTrack(destination.stream.getAudioTracks()[0]);
        }

        this.ws.startStream(canvasStream, 'test');
    };

    setStreamStatus = (status: boolean) => {
        this.isStreaming = status;
    };

    stopStream = () => {
        this.isStreaming = false;
        this.ws.stopStream();
    };

    recalculateIndexes = () => {
        const { layers } = this.selectedScene;

        for (let i = 0; i < layers.length; i++) {
            layers[i].index = i;
            layers[i].zIndex = (layers.length - 1) - i;
            this.canvas.moveTo(layers[i].fabricNode, layers[i].zIndex);
        }
    };

    addNode = async (layer: ILayer) => {
        try {
            this.selectedScene.layers.unshift(layer);
            const sceneLayer = this.selectedScene.layers.find(({ id }) => id === layer.id);

            if (sceneLayer.type !== LayerTypes.SlideShow) {
                this.canvas.add(sceneLayer.fabricNode);
            }

            this.selectLayer(sceneLayer);
            this.recalculateIndexes();

            sceneLayer.fabricNode.center();
            sceneLayer.fabricNode.on('selected', () => {
                if (sceneLayer) {
                    this.selectLayer(sceneLayer);
                }
            });

            const settings = this.getStringifiedSettings(layer);
            await fetch('/api/layers/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: layer.id,
                    name: layer.name,
                    isHidden: layer.isHidden,
                    isLocked: layer.isLocked,
                    index: layer.index,
                    zIndex: layer.zIndex,
                    type: layer.type,
                    settings,
                    width: layer.width,
                    height: layer.height,
                    left: layer.left,
                    top: layer.top,
                    sceneId: layer.sceneId,
                }),
            });
        } catch (error) {
            console.error(error);
        }
    };

    // todo util
    getStringifiedSettings = (layer: ILayer): string => {
        if (layer.type === LayerTypes.SlideShow) {
            return JSON.stringify({
                ...layer.settings,
                images: (layer.settings as ISlideShowSettings).images.map(({id}) => id),
            });
        }

        return JSON.stringify(layer.settings);
    };

    updateLayerPosition = (layer: ILayer) => {
        const { fabricNode } = layer;
        const { top, left } = fabricNode.getBoundingRect();

        layer.top = top;
        layer.left = left;
    };

    updateLayerSize = (layer: ILayer) => {
        const { fabricNode } = layer;
        const { width, height } = fabricNode.getBoundingRect();

        layer.width = width;
        layer.height = height;
    };

    updateTextLayerText = (layer: ILayer, text: string) => {
        (layer.settings as ITextSettings).text = text;
    };

    updateTextLayerColor = (layer: ILayer, color: string) => {
        (layer.settings as ITextSettings).color = color;
    };

    updateTextLayerFontFamily = (layer: ILayer, fontFamily: string) => {
        (layer.settings as ITextSettings).fontFamily = fontFamily;
    };
}
