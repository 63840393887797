import { action, makeObservable, observable } from 'mobx';
import { fabric } from 'fabric';
import { IImage, LogoPositionsEnum, NavBarTabs, PatternsEnum } from '@common-types';
import { DEFAULT_CANVAS_BG_COLOR, DEFAULT_FRAME_RATE, STORAGE_CAMERA_LABEL, STORAGE_MICRO_LABEL } from '@constants';
import { getCanvasSize } from '@utils';
import { Base } from './base-store';

export class SimpleModeStore extends Base {
    canvas: fabric.Canvas;
    tab: NavBarTabs = NavBarTabs.Branding;
    streamName: string = 'Some stream name';
    streamDate: string = 'Jan 13 - 14:43:20';
    prevLogo: fabric.Image;
    prevBg: fabric.Image;
    cameraNode: fabric.Image;
    screenShareNode: fabric.Image;
    resultSteamNodes: fabric.Image[] = [];
    logoNode: fabric.Image;
    namesNodes: {
        colorNode: fabric.Rect,
        groupNode: fabric.Group,
    }[] = [];
    cameraStream: MediaStream;
    screenShareStream: MediaStream;
    audioStream: MediaStream;
    showParticipantNames: boolean = true;
    primaryColor: string = '#4745CE';
    logoPosition: LogoPositionsEnum = LogoPositionsEnum.TopRight;
    usersOrder: { id: string; isEnabled: boolean; }[] = [];
    currentPattern: PatternsEnum = PatternsEnum.OneUser;
    video: HTMLVideoElement;
    isAudioMuted: boolean = false;
    isMicroMuted: boolean = false;
    isVideoMuted: boolean = false;
    isSettingsModalShown: boolean = false;
    currentCamera: string = null;
    currentMicro: string = null;
    isStreaming: boolean = false;
    selectedLogo: string = '';
    selectedBg: string = '';
    isScreenShare: boolean = false;

    constructor(rootStore) {
        super(rootStore);

        makeObservable(this, {
            tab: observable,
            isScreenShare: observable,
            selectedLogo: observable,
            selectedBg: observable,
            isStreaming: observable,
            usersOrder: observable,
            isSettingsModalShown: observable,
            isMicroMuted: observable,
            isVideoMuted: observable,
            streamName: observable,
            streamDate: observable,
            currentCamera: observable,
            currentMicro: observable,
            isAudioMuted: observable,
            showParticipantNames: observable,
            currentPattern: observable,
            primaryColor: observable,
            logoPosition: observable,
            changeTab: action,
            toggleParticipantNames: action,
            setPrimaryColor: action,
            setLogoPosition: action,
            addLogo: action,
            addUser: action,
            removeUser: action,
            toggleUser: action,
            selectPattern: action,
            toggleAudio: action,
            toggleMicros: action,
            toggleVideo: action,
            openSettingsModal: action,
            closeSettingsModal: action,
            setCurrentCamera: action,
            setCurrentMicro: action,
            updateCamera: action,
            updateMicro: action,
            startStream: action,
            setStreamStatus: action,
            addBg: action,
            stopScreenShare: action,
            addScreenShare: action,
        });
    }

    setCurrentCamera = (camera: string) => {
        this.currentCamera = camera;
        localStorage.setItem(STORAGE_CAMERA_LABEL, camera);
    };

    setCurrentMicro = (micro: string) => {
        this.currentMicro = micro;
        localStorage.setItem(STORAGE_MICRO_LABEL, micro);
    };

    openSettingsModal = () => {
        this.isSettingsModalShown = true;
    };

    closeSettingsModal = () => {
        this.isSettingsModalShown = false;
    };

    toggleMicros = (enabled: boolean) => {
        const cameraTracks = this.cameraStream?.getAudioTracks();
        const screenShareTracks = this.screenShareStream?.getAudioTracks();
        const audioStreamTracks = this.audioStream?.getAudioTracks();

        if (cameraTracks && cameraTracks.length) {
            this.isMicroMuted = !enabled;
            cameraTracks[0].enabled = enabled;
        }

        if (screenShareTracks && screenShareTracks.length) {
            this.isMicroMuted = !enabled;
            screenShareTracks[0].enabled = enabled;
        }

        if (audioStreamTracks && audioStreamTracks.length) {
            this.isMicroMuted = !enabled;
            audioStreamTracks[0].enabled = enabled;
        }
    };

    toggleVideo = () => {
        const cameraTracks = this.cameraStream?.getVideoTracks();
        const screenShareTracks = this.screenShareStream?.getVideoTracks();

        if (cameraTracks && cameraTracks.length) {
            this.isVideoMuted = cameraTracks[0].enabled;
            cameraTracks[0].enabled = !(cameraTracks[0].enabled);
        }

        if (screenShareTracks && screenShareTracks.length) {
            this.isVideoMuted = screenShareTracks[0].enabled;
            screenShareTracks[0].enabled = !(screenShareTracks[0].enabled);
        }
    };

    toggleAudio = () => {
        this.isAudioMuted = !this.isAudioMuted;
    };

    selectPattern = (pattern: PatternsEnum) => {
        this.currentPattern = pattern;
        this.applyPattern(this.currentPattern);
    };

    addUser = (id: string) => {
        if (this.usersOrder.every((order) => order.id !== id)) {
            this.usersOrder.push({ id, isEnabled: true });
        }
    };

    removeUser = (id: string) => {
        this.usersOrder = this.usersOrder.filter((user) => {
            return user.id !== id;
        });
    };

    toggleUser = (id: string) => {
        const user = this.usersOrder.find((user) => {
            return user.id === id;
        });

        if (user) {
            user.isEnabled = !user.isEnabled;
            this.applyPattern(this.currentPattern);
        }
    };

    setLogoPosition = (position: LogoPositionsEnum) => {
        this.logoPosition = position;

        if (this.logoNode) {
            const canvasWidth = this.canvas.getWidth();
            const canvasHeight = this.canvas.getHeight();
            const padding = canvasWidth * 0.025;

            switch (this.logoPosition) {
                case LogoPositionsEnum.TopRight: {
                    this.logoNode.left = canvasWidth - (this.logoNode.getScaledWidth() + padding);
                    this.logoNode.top = padding;
                    break;
                }
                case LogoPositionsEnum.TopLeft: {
                    this.logoNode.left = padding;
                    this.logoNode.top = padding;
                    break;
                }
                case LogoPositionsEnum.BottomRight: {
                    this.logoNode.left = canvasWidth - (this.logoNode.getScaledWidth() + padding);
                    this.logoNode.top = canvasHeight - (this.logoNode.getScaledHeight() + padding);
                    break;
                }
                case LogoPositionsEnum.BottomLeft: {
                    this.logoNode.left = padding;
                    this.logoNode.top = canvasHeight - (this.logoNode.getScaledHeight() + padding);
                    break;
                }
            }

            this.logoNode.setCoords();
        }
    };

    getEnabledUsers = (): { id: string; isEnabled: boolean; }[] => {
        return this.usersOrder.filter(({ isEnabled }) => isEnabled);
    };

    toggleParticipantNames = () => {
        this.showParticipantNames = !this.showParticipantNames;

        this.namesNodes.forEach(({ groupNode }) => {
            groupNode.visible = this.showParticipantNames;
        });
    };

    setPrimaryColor = (color: string) => {
        this.primaryColor = color;

        this.namesNodes.forEach(({ colorNode, groupNode }) => {
            colorNode.fill = this.primaryColor;
        });
    };

    init = async (canvasInstance: HTMLCanvasElement, video: HTMLVideoElement) => {
        this.canvas = new fabric.Canvas(canvasInstance, {
            ...getCanvasSize(),
            backgroundColor: DEFAULT_CANVAS_BG_COLOR,
            selection: false,
            interactive: false,
            controlsAboveOverlay: false
        });
        this.video = video;

        const self = this;
        /*fabric.util.requestAnimFrame(function render() {
            self.canvas.renderAll();
            fabric.util.requestAnimFrame(render);
        });*/
        setInterval(() => {
            self.canvas.renderAll();
        }, 20);

        if (this.rootStore.userMediaStore.selectedMicro && this.rootStore.userMediaStore.selectedCamera) {
            this.addCamera();
        }
    };

    stopScreenShare = () => {
        this.isScreenShare = false;

        if (this.screenShareNode) {
            this.canvas.remove(this.screenShareNode);
            this.screenShareNode = undefined;
        }

        if (this.screenShareStream) {
            this.screenShareStream.getTracks().map((track) => {
                track.stop();
            });
            this.screenShareStream = undefined;
        }
    };

    addCamera = async (deviceId?: string) => {
        this.stopScreenShare();

        const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
                deviceId: deviceId || this.currentMicro || this.rootStore.userMediaStore.selectedMicro.deviceId,
            },
            video: {
                width: 1280,
                height: 720,
                deviceId: deviceId || this.currentCamera || this.rootStore.userMediaStore.selectedCamera.deviceId,
            }
        });

        if (this.cameraNode) {
            this.canvas.remove(this.cameraNode);
        }

        if (this.cameraStream) {
            this.cameraStream.getTracks().forEach((track) => {
                track.stop();
            });
        }

        this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);

        const video = document.createElement('video');
        video.srcObject = stream;
        video.muted = true;
        video.width = 1280;
        video.height = 720;
        video.play();
        const node = new fabric.Image(video, {
            left: 0,
            top: 0,
            objectCaching: false,
            selectable: false,
            evented: false,
        });
        node.scaleToWidth(this.canvas.getWidth());

        this.canvas.add(node);
        this.cameraNode = node;
        this.cameraStream = stream;
        this.audioStream = stream;
        (node.getElement() as HTMLVideoElement).play();

        this.video.srcObject = stream;
        this.video.play();

        this.selectPattern(this.currentPattern);
    };

    updateCamera = async (camera: string) => {
        try {
            if (camera !== this.currentCamera) {
                this.currentCamera = camera;
                await this.addCamera();
            }
        } catch (error) {
            console.error(error);
        }
    };

    updateMicro = async (micro: string) => {
        try {
            if (micro !== this.currentMicro) {
                this.currentMicro = micro;

                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.currentMicro,
                    },
                    video: false,
                });

                this.rootStore.usersConferenceStore.changeTrack(stream.getAudioTracks()[0]);
                this.audioStream = stream;
            }
        } catch (error) {
            console.error(error)
        }
    };

    addLogo = (img: IImage) => {
        if (this.prevLogo) {
            this.canvas.remove(this.prevLogo);

            if (this.selectedLogo === img.src) {
                this.selectedLogo = '';
                this.prevLogo = undefined;
                return;
            }
        }

        this.selectedLogo = img.src;
        fabric.Image.fromURL(img.src, (node) => {
            const canvasWidth = this.canvas.getWidth();
            const padding = canvasWidth * 0.025;
            const maxImageSize = 100;

            node.scaleToWidth(maxImageSize);

            if (node.getScaledHeight() > maxImageSize) {
                node.scaleToHeight(maxImageSize);
            }

            node.left = canvasWidth - (node.getScaledWidth() + padding);
            node.top = padding;

            node.selectable = false;
            node.setCoords();
            node.evented = false;

            this.canvas.add(node);
            this.canvas.moveTo(node, 10);
            this.prevLogo = node;

            this.logoNode = node;

            this.setLogoPosition(this.logoPosition);
        });
    };

    addBg = (img: IImage) => {
        if (this.prevBg) {
            this.canvas.remove(this.prevBg);

            if (this.selectedBg === img.src) {
                this.selectedBg = '';
                this.prevBg = undefined;
                return;
            }
        }

        this.selectedBg = img.src;
        fabric.Image.fromURL(img.src, (node) => {
            node.scaleToWidth(this.canvas.getWidth());

            if (node.getScaledHeight() < this.canvas.getHeight()) {
                node.scaleToHeight(this.canvas.getHeight());
            }

            node.left = 0;
            node.top = 0;
            node.setCoords();
            node.selectable = false;
            node.evented = false;

            this.canvas.add(node);
            this.canvas.moveTo(node, 0);
            this.prevBg = node;
        });
    };

    addScreenShare = async () => {
        // @ts-ignore
        const stream = await navigator.mediaDevices.getDisplayMedia(
            {
                video: {
                    cursor: 'always',
                    width: 1920,
                    height: 1080,
                },
                audio: true,
            }
        );

        if (this.cameraNode) {
            this.canvas.remove(this.cameraNode);
        }

        const video = document.createElement('video');
        video.width = 1920;
        video.height = 1080;
        video.srcObject = stream;
        video.muted = true;

        const node = new fabric.Image(video, {
            top: 0,
            left: 0,
            objectCaching: false,
            selectable: false,
            evented: false,
        });

        node.scaleToWidth(this.canvas.getWidth());

        this.canvas.add(node);
        (node.getElement() as HTMLVideoElement).play();
        this.screenShareNode = node;
        this.isScreenShare = true;
        this.screenShareStream = stream;

        this.video.srcObject = stream;
        this.video.play();

        stream.getVideoTracks()[0].onended = () => {
            this.addCamera();
            this.canvas.remove(node);
        };

        const audioTracks = stream.getAudioTracks();

        if (audioTracks && audioTracks[0]) {
            const audioSteam = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: this.currentMicro,
                },
            });
            const audioContext = new AudioContext();
            const destination = audioContext.createMediaStreamDestination();
            const audioScreenShare = audioContext.createMediaStreamSource(stream);
            const audioCamera = audioContext.createMediaStreamSource(audioSteam);

            audioScreenShare.connect(destination);
            audioCamera.connect(destination);

            this.rootStore.usersConferenceStore.changeTrack(destination.stream.getAudioTracks()[0]);
            this.audioStream = destination.stream;
        } else {
            const audioSteam = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: this.currentMicro,
                },
            });
            this.rootStore.usersConferenceStore.changeTrack(audioSteam.getAudioTracks()[0]);
            this.audioStream = audioSteam;
        }

        this.selectPattern(this.currentPattern);
    };

    changeTab = (tab: string) => {
        this.tab = tab as NavBarTabs;
    };

    // todo utils
    getNameNodeGroup = (name: string, color: string, top: number, left: number) => {
        const brandingWidth = 5;
        const padding = 8;
        const doublePadding = padding * 2;
        const text = new fabric.Text(name, {
            fill: '#ffffff',
            fontSize: 14,
            top: padding,
            left: padding + brandingWidth,
            fontFamily: 'Inter',
        });
        const bg = new fabric.Rect({
            fill: 'rgba(22, 28, 33, 0.9)',
            width: text.getScaledWidth() + doublePadding,
            height: text.getScaledHeight() + doublePadding,
            top: 0,
            left: brandingWidth,
        });
        const colorNode = new fabric.Rect({
            fill: color,
            height: text.getScaledHeight() + doublePadding,
            width: brandingWidth,
            left: 0,
            top: 0,
            objectCaching: false,
        });
        const groupNode = new fabric.Group([ colorNode, bg, text ], {
            left,
            top,
            objectCaching: false,
            selectable: false,
            evented: false,
        });


        return { colorNode, groupNode };
    };

    addNameNode = (name: string, top: number, left: number) => {
        const nameObj = this.getNameNodeGroup(
            name,
            this.primaryColor,
            top,
            left,
        );

        nameObj.groupNode.top = top - Math.floor(nameObj.groupNode.getScaledHeight());
        nameObj.groupNode.setCoords();
        this.canvas.add(nameObj.groupNode);
        this.namesNodes.push(nameObj);
    };

    applyPattern = (pattern: PatternsEnum) => {
        const enabledUsers = this.getEnabledUsers();
        this.resultSteamNodes.forEach((node: fabric.Image) => {
            this.canvas.remove(node);
        });
        this.resultSteamNodes = [];
        this.namesNodes.forEach(({ groupNode }) => {
            this.canvas.remove(groupNode);
        });
        this.namesNodes = [];

        const canvasWidth = this.canvas.getWidth();
        const canvasHeight = this.canvas.getHeight();
        const canvasHalfWidth = canvasWidth / 2;
        const canvasHalfHeight = canvasHeight / 2;
        const { streams } = this.rootStore.usersConferenceStore;
        const padding = 16;
        const doublePadding = padding * 2;
        const halfPadding = padding / 2;

        const mainNode = this.screenShareNode ? this.screenShareNode : this.cameraNode;

        if (pattern === PatternsEnum.OneUser) {
            mainNode.scaleToWidth(canvasWidth);
            mainNode.clipPath = null;
            mainNode.left = 0;
            mainNode.top = 0;
            mainNode.setCoords();
        } else if (pattern === PatternsEnum.TwoUsers) {
            const clip1 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHeight - padding * 2,
                top: padding,
                left: padding,
                absolutePositioned: true
            });
            const clip2 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHeight - padding * 2,
                top: padding,
                left: canvasHalfWidth + halfPadding,
                absolutePositioned: true
            });

            this.addNameNode(this.rootStore.userMediaStore.name, canvasHeight - padding, padding);

            mainNode.scaleToHeight(canvasHeight - doublePadding);
            mainNode.clipPath = clip1;
            const nodeWidth = mainNode.getScaledWidth();
            mainNode.left = -((nodeWidth - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
            mainNode.top = padding;
            mainNode.setCoords();

            const video = document.createElement('video');
            video.muted = true;
            video.width = 1280;
            video.height = 720;
            video.style.backgroundColor = '#000';
            video.play();

            if (enabledUsers[0] && streams[enabledUsers[0].id]) {
                video.srcObject = streams[enabledUsers[0].id].stream;
            }

            const node = new fabric.Image(video, {
                left: 0,
                top: 0,
                objectCaching: false,
                selectable: false,
                evented: false,
            });
            node.scaleToHeight(canvasHeight - doublePadding);
            node.clipPath = clip2;

            const nodeWidth1 = node.getScaledWidth();
            node.top = padding;
            node.left = canvasHalfWidth - ((nodeWidth1 - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
            node.setCoords();
            this.canvas.add(node);
            (node.getElement() as HTMLVideoElement).play();
            this.resultSteamNodes.push(node);

            if (enabledUsers[0] && streams[enabledUsers[0].id]) {
                this.addNameNode(streams[enabledUsers[0].id].name, canvasHeight - padding, canvasHalfWidth + halfPadding);
            }

        } else if (pattern === PatternsEnum.ThreeUsers) {
            const clip1 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHeight - padding * 2,
                top: padding,
                left: padding,
                absolutePositioned: true
            });
            const clip2 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHalfHeight - (doublePadding - halfPadding),
                top: padding,
                left: canvasHalfWidth + halfPadding,
                absolutePositioned: true
            });
            const clip3 = new fabric.Rect({
                width: canvasHalfWidth - (doublePadding - halfPadding),
                height: canvasHalfHeight - (doublePadding - halfPadding),
                top: canvasHalfHeight + halfPadding,
                left: canvasHalfWidth + halfPadding,
                absolutePositioned: true
            });

            this.addNameNode(this.rootStore.userMediaStore.name, canvasHeight - padding, padding);

            mainNode.scaleToHeight(canvasHeight - doublePadding);
            mainNode.clipPath = clip1;
            const nodeWidth = mainNode.getScaledWidth();
            mainNode.left = -((nodeWidth - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
            mainNode.top = padding;
            mainNode.setCoords();

            const video = document.createElement('video');
            video.muted = true;
            video.width = 1280;
            video.height = 720;
            // video.play();

            if (enabledUsers[0] && streams[enabledUsers[0].id]) {
                video.srcObject = streams[enabledUsers[0].id].stream;
            }

            const node = new fabric.Image(video, {
                left: 0,
                top: 0,
                objectCaching: false,
                selectable: false,
                evented: false,
            });
            node.scaleToHeight(canvasHalfHeight - (doublePadding - halfPadding));

            if (node.getScaledWidth() < canvasHalfWidth - (doublePadding - halfPadding)) {
                node.scaleToWidth(canvasHalfWidth);
            }

            node.clipPath = clip2;

            const nodeWidth1 = node.getScaledWidth();
            node.top = padding;
            node.left = canvasHalfWidth - ((nodeWidth1 - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
            node.setCoords();
            this.canvas.add(node);
            (node.getElement() as HTMLVideoElement).play();
            this.resultSteamNodes.push(node);

            const video1 = document.createElement('video');
            video1.muted = true;
            video1.width = 1280;
            video1.height = 720;

            if (enabledUsers[1] && streams[enabledUsers[1].id]) {
                video1.srcObject = streams[enabledUsers[1].id].stream;
            }

            const node1 = new fabric.Image(video1, {
                left: 0,
                top: 0,
                objectCaching: false,
                selectable: false,
                evented: false,
            });
            node1.scaleToHeight(canvasHalfHeight - (doublePadding - halfPadding));

            if (node1.getScaledWidth() < canvasHalfWidth - (doublePadding - halfPadding)) {
                node1.scaleToWidth(canvasHalfWidth);
            }

            node1.clipPath = clip3;

            const nodeWidth2 = node1.getScaledWidth();
            node1.top = canvasHalfHeight + halfPadding;
            node1.left = canvasHalfWidth - ((nodeWidth2 - canvasHalfWidth - (doublePadding - halfPadding)) / 2);
            node1.setCoords();
            this.canvas.add(node1);

            (node1.getElement() as HTMLVideoElement).play();
            this.resultSteamNodes.push(node1);

            if (enabledUsers[0] && streams[enabledUsers[0].id]) {
                this.addNameNode(streams[enabledUsers[0].id].name, clip2.top + clip2.getScaledHeight(), clip2.left);
            }

            if (enabledUsers[1] && streams[enabledUsers[1].id]) {
                this.addNameNode(streams[enabledUsers[1].id].name, clip3.top + clip3.getScaledHeight(), clip3.left);
            }


        } else if (pattern === PatternsEnum.TwoUsersOneSmall) {
            const clip1 = new fabric.Rect({
                width: canvasWidth - padding * 2,
                height: canvasHeight - padding * 2,
                top: padding,
                left: padding,
                absolutePositioned: true
            });

            mainNode.scaleToWidth(canvasWidth - padding * 2);
            mainNode.clipPath = clip1;
            mainNode.center();
            mainNode.setCoords();

            this.addNameNode(this.rootStore.userMediaStore.name, canvasHeight - padding, padding);

            const video = document.createElement('video');
            video.muted = true;
            video.width = 1280;
            video.height = 720;
            video.play();

            if (enabledUsers[0] && streams[enabledUsers[0].id]) {
                video.srcObject = streams[enabledUsers[0].id].stream;
            }

            const node = new fabric.Image(video, {
                left: 0,
                top: 0,
                objectCaching: false,
                selectable: false,
                evented: false,
            });
            const width = canvasWidth / 4;
            node.scaleToWidth(width);
            const height = node.getScaledHeight();
            node.top = canvasHeight - height - padding * 2;
            node.left = canvasWidth - width - padding * 2;
            node.setCoords();
            this.canvas.add(node);
            (node.getElement() as HTMLVideoElement).play();
            this.resultSteamNodes.push(node);

            if (enabledUsers[0] && streams[enabledUsers[0].id]) {
                this.addNameNode(streams[enabledUsers[0].id].name, node.top + node.getScaledHeight(), node.left);
            }
        }
    };

    setStreamStatus = (status: boolean) => {
        this.isStreaming = status;
    };

    startStream = async () => {
        this.isStreaming = true;
        // @ts-ignore
        const canvasStream: MediaStream = await this.canvas.getElement().captureStream(DEFAULT_FRAME_RATE);
        const audioContext = new AudioContext();
        const destination = audioContext.createMediaStreamDestination();


        const audioStream = audioContext.createMediaStreamSource(this.audioStream);
        audioStream.connect(destination);

        Object.keys(this.rootStore.usersConferenceStore.streams).map((key: string) => {
            const { stream } = this.rootStore.usersConferenceStore.streams[key];

            if (stream && stream.getAudioTracks() && stream.getAudioTracks().length) {
                const userStream = audioContext.createMediaStreamSource(stream);
                userStream.connect(destination);
            }
        });

        if (destination.stream.getAudioTracks() && destination.stream.getAudioTracks().length) {
            canvasStream.addTrack(destination.stream.getAudioTracks()[0]);
        }

        this.rootStore.usersConferenceStore.startStream(canvasStream, 'test1');
    };
}
