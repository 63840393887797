import { v4 } from 'uuid';
import { IScene } from '@common-types';

export const getEmptyScene = (
    name: string,
    index: number,
    projectId: string,
    isStartingScene: boolean = false
): IScene => {
    return {
        id: v4(),
        audioSources: [],
        isStartingScene,
        layers: [],
        preview: '',
        name,
        projectId,
        lastSelectedLayer: null,
        index,
    };
};
