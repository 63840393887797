import { Logger } from '@services';
import {
    DEFAULT_TIME_SLICE,
    DEFAULT_VIDEO_BITS_PER_SECOND,
    DEFAULT_AUDIO_BITS_PER_SECOND,
    DEFAULT_MIME_TYPE,
    DEFAULT_CODEC,
} from '@constants';
import { StreamStore } from '../stores/stream-store';
import { SimpleModeStore } from '../stores/simple-mode-store';

export class Websockets {
    private streamKey: string = '';
    private ws: WebSocket = null;
    private mediaRecorder;
    private mediaStream: MediaStream;
    private store: StreamStore | SimpleModeStore = null;

    constructor(store: StreamStore | SimpleModeStore) {
        this.store = store;
    }

    startStream = (stream: MediaStream, streamKey: string) => {
        try {
            Logger.info(streamKey, 'stream started');

            this.streamKey = streamKey;
            this.ws = new WebSocket(`wss://localhost:8443`);

            this.ws.addEventListener('open', () => {
                Logger.log(streamKey, 'ws connection open');
                this.mediaStream = stream;
                // @ts-ignore
                this.mediaRecorder = new MediaRecorder(this.mediaStream, {
                    mimeType: `${DEFAULT_MIME_TYPE};${DEFAULT_CODEC}`,
                    audioBitsPerSecond : DEFAULT_AUDIO_BITS_PER_SECOND,
                    videoBitsPerSecond : DEFAULT_VIDEO_BITS_PER_SECOND,
                });

                this.mediaRecorder.addEventListener('dataavailable', (e) => {
                    this.ws && this.ws.send(e.data);
                });

                this.mediaRecorder.addEventListener('stop', () => {
                    Logger.warn('mediaRecorder stoped');
                    this.ws && this.ws.close();
                });


                this.mediaRecorder.addEventListener('start', () => {
                    Logger.warn('mediaRecorder start');
                });
                this.mediaRecorder.start(DEFAULT_TIME_SLICE);
            });

            this.ws.addEventListener('close', () => {
                Logger.warn(streamKey, 'ws connection closed');
                this.store.setStreamStatus(false);
                this.mediaRecorder && this.mediaRecorder.stop();
            });
        } catch (error) {
            Logger.error(error, 'ws connection');
        }
    };

    stopStream = () => {
        try {
            Logger.info(this.streamKey, 'stream stopped');

            if (this.ws && this.ws.close) {
                this.ws.close();
            }

            if (this.mediaRecorder && this.mediaRecorder.stop) {
                this.mediaRecorder.stop();
            }

            this.mediaStream = null;
            this.mediaRecorder = null;
            this.ws = null;
            this.streamKey = '';
        } catch (error) {
            Logger.error(error, `error till stop stream = ${this.streamKey}`);
        }
    };
}
