import { AppStore } from './app-store';
import { StreamStore } from './stream-store';
import { GuestStore } from './guest-store';
import { UserMediaStore } from './user-media-store';
import { SimpleModeStore } from './simple-mode-store';
import { UsersConferenceStore } from './users-conference-store';


export class RootStore {
    readonly appStore: AppStore;
    readonly streamStore: StreamStore;
    readonly guestStore: GuestStore;
    readonly userMediaStore: UserMediaStore;
    readonly simpleModeStore: SimpleModeStore;
    readonly usersConferenceStore: UsersConferenceStore;

    constructor() {
        this.appStore = new AppStore(this);
        this.streamStore = new StreamStore(this);
        this.guestStore = new GuestStore(this);
        this.userMediaStore = new UserMediaStore(this);
        this.simpleModeStore = new SimpleModeStore(this);
        this.usersConferenceStore = new UsersConferenceStore(this);
    }
}
