import { fabric } from 'fabric';

export const animatePromise = (
    image: fabric.Image,
    options: { [key: string]: string | number },
    duration: number
): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            image.scaleToWidth(200);
            image.animate(options, {
                duration,
                onComplete: () => {
                    resolve();
                },
            });
        } catch (error) {
            reject(error);
        }
    });
};

export const delay = (time: number): Promise<void> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, time);
    });
};
