import { fabric } from 'fabric';
import { v4 } from 'uuid';
import { ISlideShowOptions, Animations, IImage } from '@common-types';
import { delay, animatePromise } from '@utils';

export class SlideShow {
    readonly layerId: string;
    readonly slideShowId: string;
    private readonly canvas: fabric.Canvas = null;
    private options: ISlideShowOptions = null;
    private imageNode: fabric.Image = null;
    private currentImageIndex: number = 0;
    private isAnimated: boolean = true;

    constructor(canvas: fabric.Canvas, layerId: string) {
        this.canvas = canvas;
        this.slideShowId = v4();
        this.layerId = layerId;
    }

    create = (options: ISlideShowOptions): Promise<fabric.Image> => {
        this.options = options;

        return new Promise((resolve, reject) => {
            fabric.Image.fromURL(this.getNextImage(), (imageNode) => {
                this.imageNode = imageNode;
                this.imageNode.set({
                    opacity: 0,
                });

                this.imageNode.scaleToWidth(200);
                this.canvas.add(this.imageNode);
                this.isAnimated = true;
                this.animationLoop();

                resolve(this.imageNode);
            });
        });
    };

    update = (options: ISlideShowOptions) => {
        this.options = options;
        this.currentImageIndex = 0;
        this.animationLoop();
    };

    animate = async (): Promise<void> => {
        const { animationSpeed, imageDuration } = this.options;
        const duration: number = animationSpeed * 1000;

        this.imageNode.scaleToWidth(200);
        await animatePromise(this.imageNode, { opacity: '1' }, duration);
        await delay(imageDuration * 1000);
        await animatePromise(this.imageNode, { opacity: '0' }, duration);
    };

    getNextImage = (): string => {
        const { random, images } = this.options;

        if (random) {
            return '';
        }

        const item: IImage = images[this.currentImageIndex];
        this.currentImageIndex++;

        if (this.currentImageIndex >= images.length) {
            this.currentImageIndex = 0;
        }

        return item.src;
    };

    setSrc = (src: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            this.imageNode.setSrc(src, () => {
                resolve();
            });
        });
    };

    animationLoop = async () => {
        if (!this.isAnimated) {
            return;
        }
        console.log('animationLoop');
        const { loopDelay, images } = this.options;
        const loop: number = loopDelay * 1000;

        for (const item of images) {
            if (!this.isAnimated) {
                return;
            }

            await this.setSrc(item.src);
            await this.animate();
        }

        await delay(loop);

        return this.animationLoop();
    };

    stop = () => {
        this.isAnimated = false;
        this.canvas.remove(this.imageNode);
    };
}
