import React from 'react';
import { inject, observer } from 'mobx-react';
import { PatternsEnum } from '@common-types';
import './styles.scss';

interface IProps {
    selectedPattern: PatternsEnum;
    onPatternSelect: (pattern: PatternsEnum) => void;
}

@inject()
@observer
export class PatternsSection extends React.Component<IProps> {
    onPatternSelect = (pattern: PatternsEnum) => {
        this.props.onPatternSelect(pattern);
    };

    render(): React.ReactNode {
        const { selectedPattern } = this.props;

        return (
            <div className='patterns-section'>
                <div
                    className={`patterns-section__pattern ${selectedPattern === PatternsEnum.OneUser ? 'selected' : ''}`}
                    onClick={() => this.onPatternSelect(PatternsEnum.OneUser)}
                >
                    <div className='patterns-section__one-user'>
                        <div className='patterns-section__item' />
                    </div>
                </div>
                <div
                    className={`patterns-section__pattern ${selectedPattern === PatternsEnum.TwoUsers ? 'selected' : ''}`}
                    onClick={() => this.onPatternSelect(PatternsEnum.TwoUsers)}
                >
                    <div className='patterns-section__two-users'>
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                    </div>
                </div>
                <div
                    className={`patterns-section__pattern ${selectedPattern === PatternsEnum.ThreeUsers ? 'selected' : ''}`}
                    onClick={() => this.onPatternSelect(PatternsEnum.ThreeUsers)}
                >
                    <div className='patterns-section__three-users'>
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                    </div>
                </div>
                <div
                    className={`patterns-section__pattern ${selectedPattern === PatternsEnum.TwoUsersOneSmall ? 'selected' : ''}`}
                    onClick={() => this.onPatternSelect(PatternsEnum.TwoUsersOneSmall)}
                >
                    <div className='patterns-section__small-user'>
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                    </div>
                </div>
                <div
                    className={`patterns-section__pattern ${selectedPattern === PatternsEnum.SixUsers ? 'selected' : ''}`}
                    onClick={() => this.onPatternSelect(PatternsEnum.SixUsers)}
                >
                    <div className='patterns-section__six-users'>
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                    </div>
                </div>
                <div
                    className={`patterns-section__pattern ${selectedPattern === PatternsEnum.FiveUsers ? 'selected' : ''}`}
                    onClick={() => this.onPatternSelect(PatternsEnum.FiveUsers)}
                >
                    <div className='patterns-section__five-users'>
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                        <div className='patterns-section__item' />
                    </div>
                </div>
            </div>
        );
    }
}
