export enum Routes {
    SelectStreamPlatforms = '/select-stream-platforms',
    SelectMedia = '/select-media',
    SimpleMode = '/simple',
    GuestMode = '/guest',
    AdvancedMode = '/advanced',
}

export const URLS = {

};

export enum VideoResolutions {
    High = 'High Definition (720p @ 30 fps)',
}

export enum NavBarTabs {
    Branding = 'branding',
    Captions = 'captions',
    Chat = 'chat',
}
