import React from 'react';
import { observer } from 'mobx-react';
import './styles.scss';

interface IProps {
    stream: MediaStream;
    isAudioMuted: boolean;
}

@observer
export class StreamerVideo extends React.Component<IProps> {
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

    componentDidMount(): void {
        this.videoRef.current.srcObject = this.props.stream;
    }

    render(): React.ReactNode {
        const { stream, isAudioMuted } = this.props;

        if (!stream) {
            return null;
        }

        return (
            <div className='streamer-video'>
                <video
                    className="streamer-video__video"
                    ref={this.videoRef}
                    autoPlay={true}
                    muted={isAudioMuted}
                />
            </div>
        );
    }
}
