import { action, observable, makeObservable } from 'mobx';
import { VideoResolutions } from '@common-types';
import {
    STORAGE_CAMERA_LABEL,
    STORAGE_MICRO_LABEL,
    STORAGE_NAME_LABEL,
} from '@constants';
import { Base } from './base-store';

export class UserMediaStore extends Base {
    name: string = '';
    cameras: MediaDeviceInfo[] = [];
    micros: MediaDeviceInfo[] = [];
    selectedCamera: MediaDeviceInfo = null;
    selectedMicro: MediaDeviceInfo = null;
    videoElement: HTMLVideoElement = null;
    videoResolution: VideoResolutions = VideoResolutions.High;
    stream: MediaStream;


    constructor(rootStore) {
        super(rootStore);

        makeObservable(this, {
            name: observable,
            cameras: observable,
            micros: observable,
            selectedCamera: observable,
            selectedMicro: observable,
            videoResolution: observable,
            changeName: action,
            setCameras: action,
            setMicros: action,
            selectCamera: action,
            selectMicro: action,
            init: action,
        });
    }

    init = (videoElement: HTMLVideoElement) => {
        this.name = localStorage.getItem(STORAGE_NAME_LABEL) || '';
        const cameraId = localStorage.getItem(STORAGE_CAMERA_LABEL) || '';
        const microId = localStorage.getItem(STORAGE_MICRO_LABEL) || '';

        if (cameraId && this.cameras) {
            const selectedCamera = this.cameras.find(({ deviceId }) => {
                return deviceId === cameraId;
            });

            if (selectedCamera) {
                this.selectedCamera = selectedCamera;
            }
        }

        if (microId && this.micros) {
            const selectedMicro = this.micros.find(({ deviceId }) => {
                return deviceId === microId;
            });

            if (selectedMicro) {
                this.selectedMicro = selectedMicro;
            }
        }

        if (videoElement) {
            this.videoElement = videoElement;
            this.setStream();
        }
    };

    setStream = async () => {
        try {
            if (this.selectedCamera && this.videoElement) {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: {
                        deviceId: this.selectedCamera.deviceId,
                        width: 1280,
                        height: 720,
                    }
                });

                if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                }

                this.stream = stream;
                this.videoElement.srcObject = stream;
            }
        } catch (error) {
            console.error(error);
        }

    };

    changeName = (name: string) => {
        this.name = name;

        localStorage.setItem(STORAGE_NAME_LABEL, name);
    };

    selectCamera = (camera: MediaDeviceInfo) => {
        this.selectedCamera = camera;

        localStorage.setItem(STORAGE_CAMERA_LABEL, camera.deviceId);
        this.setStream();
    };

    selectMicro = (micro: MediaDeviceInfo) => {
        this.selectedMicro = micro;

        localStorage.setItem(STORAGE_MICRO_LABEL, micro.deviceId);
    };

    setCameras = (items: MediaDeviceInfo[]) => {
        this.cameras = items;
        const defaultCamera = items.find(({ deviceId }) => deviceId === 'default');

        if (defaultCamera) {
            this.selectedCamera = defaultCamera;
        }

        if (items.length === 1 && !defaultCamera) {
            this.selectedCamera = items[0];
        }
    };

    setMicros = (items: MediaDeviceInfo[]) => {
        this.micros = items;

        const defaultMicro = items.find(({ deviceId }) => deviceId === 'default');

        if (defaultMicro) {
            this.selectedMicro = defaultMicro;
        }

        if (items.length === 1 && !defaultMicro) {
            this.selectedMicro = items[0];
        }
    };
}
