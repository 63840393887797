import React from 'react';

interface IProps {}

export const AudioOffIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5416 11.1833C13.6666 10.8083 13.75 10.4167 13.75 10C13.75 8.525 12.9 7.25833 11.6666 6.64166V9.30833L13.5416 11.1833Z" fill="white"/>
            <path d="M15.8333 10C15.8333 11.0084 15.575 11.95 15.125 12.7667L16.3416 13.9834C17.0666 12.825 17.5 11.4667 17.5 10C17.5 6.80836 15.5 4.07503 12.6833 3.00003C12.1916 2.80836 11.6666 3.1917 11.6666 3.7167V3.87503C11.6666 4.1917 11.875 4.4667 12.175 4.58336C14.3166 5.45003 15.8333 7.55003 15.8333 10Z" fill="white"/>
            <path d="M1.74998 2.92502C1.42498 3.25002 1.42498 3.77502 1.74998 4.10002L5.14164 7.50002H3.33331C2.87498 7.50002 2.49998 7.87502 2.49998 8.33336V11.6667C2.49998 12.125 2.87498 12.5 3.33331 12.5H5.83331L8.57498 15.2417C9.09998 15.7667 9.99998 15.3917 9.99998 14.65V12.35L12.7666 15.1167C12.575 15.225 12.375 15.3167 12.175 15.4084C11.8666 15.5417 11.675 15.8417 11.675 16.1667C11.675 16.75 12.2583 17.1667 12.8 16.95C13.2166 16.7834 13.625 16.575 14 16.3417L15.9 18.2417C16.225 18.5667 16.75 18.5667 17.075 18.2417C17.4 17.9167 17.4 17.3917 17.075 17.0667L2.92498 2.92502C2.59998 2.60002 2.07498 2.60002 1.74998 2.92502Z" fill="white"/>
            <path d="M10 7.64168V5.34168C10 4.60001 9.10001 4.22501 8.57501 4.75001L7.84167 5.49168L10 7.64168Z" fill="white"/>
        </svg>
    );
};
