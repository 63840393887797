import React from 'react';

interface IProps {}

export const CameraOffIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 11.8333V7.425C17.5 6.68334 16.6 6.30834 16.075 6.83334L14.1667 8.75V5.83334C14.1667 5.375 13.7917 5 13.3333 5H8.65834L16.0833 12.425C16.6 12.95 17.5 12.575 17.5 11.8333ZM2.25834 2.13334C1.93334 2.45834 1.93334 2.98334 2.25834 3.30834L3.94167 5H3.33334C2.875 5 2.5 5.375 2.5 5.83334V14.1667C2.5 14.625 2.875 15 3.33334 15H13.3333C13.5083 15 13.6583 14.9333 13.7917 14.85L15.8583 16.9167C16.1833 17.2417 16.7083 17.2417 17.0333 16.9167C17.3583 16.5917 17.3583 16.0667 17.0333 15.7417L3.43334 2.13334C3.10834 1.80834 2.58334 1.80834 2.25834 2.13334Z" fill="white"/>
        </svg>
    );
};
