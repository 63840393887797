import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { VideoIcon, ArrowBottomIcon } from '@assets/icons';
import { PurpleButton } from '@components';
import { DeviceTypes, Routes, VideoResolutions } from '@common-types';
import { AppStore, UserMediaStore } from '@services';
import bg from '../../../assets/images/bg.png';
import './styles.scss';

interface IProps {
    AppStore?: AppStore;
    UserMediaStore?: UserMediaStore;
}

@inject('AppStore', 'UserMediaStore')
@observer
export class SelectMedia extends React.Component<IProps> {
    videoRef: React.RefObject<HTMLVideoElement> = React.createRef();

    getRoute = (): Routes => {
        const { isSimpleMode, isUserAuthorized } = this.props.AppStore!;

        if (isUserAuthorized) {
            return isSimpleMode ? Routes.SimpleMode : Routes.AdvancedMode;
        }

        return Routes.GuestMode;
    };

    async componentDidMount() {
        try {
            const { setCameras, setMicros, init } = this.props.UserMediaStore!;
            const devices = await navigator.mediaDevices.enumerateDevices();

            const cameras = devices.filter(({ kind }) => {
                return kind === DeviceTypes.Video;
            });
            const micros = devices.filter(({ kind }) => {
                return kind === DeviceTypes.Audio;
            });

            setCameras(cameras);
            setMicros(micros);
            init(this.videoRef.current);
        } catch (error) {
            console.error(error);
        }
    }

    getDevice = (arr: MediaDeviceInfo[], id: string): MediaDeviceInfo | undefined => {
        return arr.find(({ deviceId }) => {
            return deviceId === id;
        });
    };

    onCameraSelect = async (value) => {
        const { cameras, selectCamera } = this.props.UserMediaStore!;

        const device = this.getDevice(cameras, value);

        if (device) {
            selectCamera(device);
        }
    };

    onMicroSelect = (value) => {
        const { micros, selectMicro } = this.props.UserMediaStore!;

        const device = this.getDevice(micros, value);

        if (device) {
            selectMicro(device);
        }
    };

    onNameChange = (e) => {
        this.props.UserMediaStore!.changeName(e.target.value);
    };

    componentWillUnmount(): void {
        const { stream } = this.props.UserMediaStore!;

        if (stream) {
            stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
    }

    render(): React.ReactNode {
        const {
            name,
            selectedCamera,
            selectedMicro,
            micros,
            cameras,
            videoResolution,
        } = this.props.UserMediaStore!;
        const isEnabled: boolean = !!(
            name && videoResolution && (selectedMicro || selectedCamera)
        );

        return (
            <div className='select-media'>
                <div className='select-media__bg' style={{ backgroundImage: `url(${bg})` }} />
                <div className='select-media__wrapper'>
                    <div className='select-media__top'>
                        <div className='select-media__icon'>
                            <VideoIcon />
                        </div>
                        <div className='select-media__header'>
                            <FormattedMessage id='accessing.media' />
                        </div>
                        <div className='select-media__description'>
                            <FormattedMessage id='to.create.broadcast' />
                        </div>
                        <div className='select-media__camera-wrapper'>
                            <video
                                className='select-media__camera-preview'
                                muted={true}
                                autoPlay={true}
                                ref={this.videoRef}
                            />
                        </div>
                        <div className='select-media__selects'>
                            <div className='select-media__left'>
                                <div className='select-media__input-wrapper with-margin'>
                                    <div className='select-media__label'>
                                        <FormattedMessage id='your.display.name' />
                                    </div>
                                    <div className='select-media__input'>
                                        <Input
                                            value={name}
                                            onChange={this.onNameChange}
                                        />
                                    </div>
                                </div>
                                <div className='select-media__input-wrapper'>
                                    <div className='select-media__label'>
                                        <FormattedMessage id='audio.input' />
                                    </div>
                                    <div className='select-media__select'>
                                        <Select
                                            value={selectedMicro?.deviceId}
                                            onChange={this.onMicroSelect}
                                            suffixIcon={ArrowBottomIcon}
                                        >
                                            {
                                                micros.map(({ deviceId, label }) => {
                                                    return (
                                                        <Select.Option key={deviceId} value={deviceId}>
                                                            {label || 'Micro'}
                                                        </Select.Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className='select-media__right'>
                                <div className='select-media__input-wrapper with-margin'>
                                    <div className='select-media__label'>
                                        <FormattedMessage id='video.input' />
                                    </div>
                                    <div className='select-media__select'>
                                        <Select
                                            value={selectedCamera?.deviceId}
                                            onChange={this.onCameraSelect}
                                            suffixIcon={ArrowBottomIcon}
                                        >
                                            {
                                                cameras.map(({ deviceId, label }) => {
                                                    return (
                                                        <Select.Option key={deviceId} value={deviceId}>
                                                            {label || 'Camera'}
                                                        </Select.Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className='select-media__input-wrapper'>
                                    <div className='select-media__label'>
                                        <FormattedMessage id='video.resolution' />
                                    </div>
                                    <div className='select-media__select'>
                                        <Select
                                            value={videoResolution}
                                            disabled={true}
                                            onChange={this.onMicroSelect}
                                            suffixIcon={ArrowBottomIcon}
                                        >

                                            <Select.Option value={VideoResolutions.High}>
                                                {VideoResolutions.High}
                                            </Select.Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='select-media__actions'>
                        {
                            !isEnabled
                                ? (
                                    <PurpleButton disabled={!isEnabled}>
                                        <FormattedMessage id='lets.go' />
                                    </PurpleButton>
                                )
                                : (
                                <Link to={`${this.getRoute()}${location.search}`}>
                                    <PurpleButton>
                                        <FormattedMessage id='lets.go' />
                                    </PurpleButton>
                                </Link>
                                )
                        }
                    </div>
                </div>
            </div>
        );
    }
}
