import React from 'react';

interface IProps {}

export const BroadcastIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.99999 8.99997C2.00474 6.67719 2.81794 4.42851 4.29999 2.63997C4.46973 2.43575 4.55139 2.17247 4.52701 1.90805C4.50263 1.64362 4.3742 1.39971 4.16999 1.22997C3.96577 1.06023 3.70249 0.97857 3.43806 1.00295C3.17364 1.02733 2.92973 1.15575 2.75999 1.35997C0.984708 3.51021 0.0136566 6.21157 0.0136566 8.99997C0.0136566 11.7884 0.984708 14.4897 2.75999 16.64C2.85401 16.7529 2.97175 16.8436 3.10483 16.9059C3.23792 16.9681 3.38308 17.0002 3.52999 17C3.76364 17.0004 3.99008 16.9191 4.16999 16.77C4.27125 16.686 4.35495 16.5829 4.4163 16.4666C4.47765 16.3502 4.51545 16.2229 4.52752 16.0919C4.5396 15.961 4.52572 15.8289 4.48667 15.7033C4.44763 15.5777 4.38419 15.461 4.29999 15.36C2.81794 13.5714 2.00474 11.3228 1.99999 8.99997Z" fill="white"/>
            <path d="M9.99998 11C11.1045 11 12 10.1045 12 8.99997C12 7.8954 11.1045 6.99997 9.99998 6.99997C8.89541 6.99997 7.99998 7.8954 7.99998 8.99997C7.99998 10.1045 8.89541 11 9.99998 11Z" fill="white"/>
            <path d="M15.06 5.47998C15.0023 5.35247 14.9186 5.23848 14.8141 5.14537C14.7097 5.05226 14.5869 4.9821 14.4536 4.93944C14.3204 4.89677 14.1796 4.88255 14.0405 4.89768C13.9014 4.91282 13.767 4.95698 13.6461 5.02731C13.5251 5.09764 13.4203 5.19257 13.3383 5.30596C13.2563 5.41936 13.199 5.5487 13.1702 5.68561C13.1413 5.82253 13.1415 5.96398 13.1708 6.10081C13.2 6.23764 13.2577 6.36682 13.34 6.47998C13.7788 7.24007 14.0098 8.10229 14.0098 8.97998C14.0098 9.85766 13.7788 10.7199 13.34 11.48C13.271 11.5927 13.2251 11.718 13.2049 11.8486C13.1846 11.9792 13.1905 12.1126 13.2221 12.2409C13.2537 12.3692 13.3105 12.49 13.3891 12.5963C13.4676 12.7025 13.5665 12.7922 13.68 12.86C13.839 12.949 14.0177 12.9971 14.2 13C14.3725 13.001 14.5424 12.9573 14.6931 12.8732C14.8438 12.7891 14.9702 12.6674 15.06 12.52C15.6685 11.4539 15.9886 10.2475 15.9886 9.01998C15.9886 7.79242 15.6685 6.58607 15.06 5.51998V5.47998Z" fill="white"/>
            <path d="M6.31997 5.13997C6.20722 5.07102 6.08192 5.02511 5.95131 5.00488C5.8207 4.98466 5.68738 4.99052 5.55906 5.02214C5.43073 5.05375 5.30995 5.1105 5.20368 5.18908C5.09742 5.26766 5.00779 5.36653 4.93997 5.47997C4.33141 6.54606 4.01134 7.7524 4.01134 8.97996C4.01134 10.2075 4.33141 11.4139 4.93997 12.48C5.02467 12.6348 5.14886 12.7645 5.29991 12.8558C5.45095 12.9472 5.62349 12.9969 5.79997 13C5.98223 12.9971 6.16092 12.949 6.31997 12.86C6.43341 12.7921 6.53228 12.7025 6.61086 12.5962C6.68945 12.49 6.74619 12.3692 6.7778 12.2409C6.80942 12.1125 6.81528 11.9792 6.79506 11.8486C6.77483 11.718 6.72892 11.5927 6.65997 11.48C6.22113 10.7199 5.9901 9.85764 5.9901 8.97996C5.9901 8.10228 6.22113 7.24006 6.65997 6.47997C6.78675 6.2558 6.82216 5.99137 6.75882 5.74175C6.69548 5.49213 6.53829 5.27657 6.31997 5.13997Z" fill="white"/>
            <path d="M17.25 1.35997C17.1659 1.25885 17.0628 1.17528 16.9465 1.11402C16.8301 1.05276 16.7028 1.01502 16.5719 1.00295C16.3075 0.97857 16.0442 1.06023 15.84 1.22997C15.6358 1.39971 15.5073 1.64362 15.483 1.90805C15.4586 2.17247 15.5402 2.43575 15.71 2.63997C17.1859 4.43083 17.9931 6.67927 17.9931 8.99997C17.9931 11.3207 17.1859 13.5691 15.71 15.36C15.6258 15.461 15.5623 15.5777 15.5233 15.7033C15.4843 15.8289 15.4704 15.961 15.4824 16.0919C15.4945 16.2229 15.5323 16.3502 15.5937 16.4666C15.655 16.5829 15.7387 16.686 15.84 16.77C16.0199 16.9191 16.2463 17.0004 16.48 17C16.6269 17.0002 16.7721 16.9681 16.9051 16.9059C17.0382 16.8436 17.156 16.7529 17.25 16.64C19.0253 14.4897 19.9963 11.7884 19.9963 8.99997C19.9963 6.21157 19.0253 3.51021 17.25 1.35997Z" fill="white"/>
        </svg>
    );
};
