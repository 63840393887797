import React from 'react';

interface IProps {}

export const TwitchIcon: React.FunctionComponent<IProps> = (props: IProps): React.ReactElement => {
    return (
        <svg width="112" height="38" viewBox="0 0 112 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M105.972 5.94239H98.7536V0H87.9131L80.695 5.94239H74.0586L71.0444 8.91358V5.94239H65.0425V0H43.3617V5.94239H19.2748L13.2465 0H0V26.1152L6.02833 32.3443L16.8687 37.9739H24.0869V36.2016L27.7092 37.9739H42.1719L44.5779 34.4033L45.7942 37.9739H54.2021V34.4033L57.8244 37.9739H67.4485L68.0567 34.4033L71.0708 37.9739H83.1011L86.7233 34.4033V37.9739H91.5354L95.1577 34.4033V37.9739H103.592L112.026 30.8587V11.8587L105.972 5.94239ZM15.6525 15.4294H9.62418V22.5446H15.6525V29.6598H7.21813L2.40604 24.9163V2.37174H9.62418V8.31413H15.6525V15.4294ZM43.3617 25.5158L39.1313 29.6859H18.0585V8.31413H25.2767V22.5706H27.6827V8.31413H34.9009V22.5706H37.3069V8.31413H43.3617V25.5158ZM52.9858 29.6859H45.7677V8.31413H52.9858V29.6859ZM52.9858 5.94239H45.7677V2.37174H52.9858V5.94239ZM68.6384 15.4294H62.61V22.5446H68.6384V29.6598H60.204L55.3919 24.9163V2.37174H62.61V8.31413H68.6384V15.4294ZM86.6969 15.4294H78.2625V22.5446H86.6969V29.6598H75.8565L71.0444 24.9163V13.0576L75.8565 8.31413H86.6969V15.4294ZM109.594 29.6859H102.376V15.4294H96.3475V29.6859H89.1029V2.37174H96.3211V8.31413H104.755L109.568 13.0576V29.6859H109.594Z" fill="white"/>
        </svg>
    );
};
