import enUS from 'antd/es/locale/en_US';
import ruRu from 'antd/es/locale/ru_RU';
import { Locale } from 'antd/lib/locale-provider';
import * as enMessages from './en.json';
import * as ruMessages from './ru.json';

export enum Locales {
    English = 'en',
    Russian = 'ru',
}

const messagesMap = {
    [Locales.English]: enMessages,
    [Locales.Russian]: ruMessages,
};
const antLocalesMap: { [key: string]: Locale } = {
    [Locales.English]: enUS,
    [Locales.Russian]: ruRu,
};

export const getMessages = (locale: Locales) => {
    return messagesMap[locale] || enMessages;
};

export const getAntLocale = (locale: Locales): Locale => {
    return antLocalesMap[locale] || enUS;
};
